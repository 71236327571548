import { entries, first, groupBy, isArray, isNumber, isString, pick, uniq, uniqBy } from 'lodash';
import { isValid } from 'date-fns';

import { notNil } from '@/lib/utils';
import { ListRecord, NestedList, flattenNestedList } from '@/explore/grouping';
import { ChartType, Field, Grouping, SeriesViewOptions, Visualisation } from '@/explore/types';
import { Json } from '@/lib/types';
import { formatPropertyValue } from '@/explore/utils/format';

import {
  GroupedChartData,
  ChartData,
  TimeSeriesData,
  CategoryData,
  SeriesMetaData,
  PrimaryGroupSeriesMetaData,
  SingleSeriesMetaData,
  GroupedTimeSeriesData,
  isGroupedValues,
  ChartItem,
  ChartItemValues,
  GroupedItemValues,
  ItemValues,
} from './types';
import { getChartColor } from '../utils';
import { HistogramDataItem } from '../histogram';

const convertJsonToNum = (val: Json): number | null => {
  if (isString(val)) {
    return parseFloat(val);
  }

  if (isNumber(val)) {
    return val;
  }

  if (val === null) {
    return null;
  }

  throw new Error('Invalid value');
};

const getFieldLabel = (key: string, fields: Field[]): string => {
  const field = fields.find((field) => field.key === key);
  if (!field) {
    return key;
  }
  return field.name.length ? field.name : key;
};

interface AxisOptions {
  right: { keys: string[] };
}

type ChartDataGenerator<T extends ChartData> = (params: {
  records: ListRecord[];
  valueKeys: string[];
  fields: Field[];
  axisGroupKey: string;
  seriesGroupKey?: string;
  groupKey?: string;
  axes?: AxisOptions;
  seriesViewOptions?: SeriesViewOptions;
}) => T;

const generateValues = (valueKeys: string[], records: ListRecord[]) => {
  return valueKeys.reduce<ItemValues>(
    (acc, valueKey) => ({
      ...acc,
      [valueKey]: convertJsonToNum(records[0][valueKey]),
    }),
    {},
  );
};

const generateCombinedKey = (groupName: string, valueKey: string) => `${groupName}_${valueKey}`;

const generateGroupedCombinedValues = (
  valueKeys: string[],
  combineKey: string,
  records: ListRecord[],
  groupKey: string,
): { group: string; values: ItemValues }[] =>
  entries(groupBy(records, groupKey)).map(([groupValue, groupedRecords]) => ({
    group: groupValue,
    values: generateCombinedValues(valueKeys, combineKey, groupedRecords),
  }));

const generateCombinedValues = (
  valueKeys: string[],
  combineKey: string,
  records: ListRecord[],
): ItemValues => {
  return Object.entries(groupBy(records, combineKey)).reduce<ItemValues>(
    (acc, [combineValue, combinedRecords]) => ({
      ...acc,
      ...valueKeys.reduce<ItemValues>(
        (acc, valueKey) => ({
          ...acc,
          [generateCombinedKey(combineValue, valueKey)]: convertJsonToNum(
            combinedRecords[0][valueKey],
          ),
        }),
        {},
      ),
    }),
    {},
  );
};

const generateSeries = (
  valueKeys: string[],
  fields: Field[],
  items: Exclude<ChartData, GroupedTimeSeriesData>['items'],
  axes?: AxisOptions,
  seriesViewOptions?: SeriesViewOptions,
): SeriesMetaData[] =>
  valueKeys.map((valueKey, idx) => {
    const field = fields.find((field) => field.key === valueKey);
    return {
      key: valueKey,
      type: field?.type ?? 'Number',
      format: field?.format,
      label: field?.name ?? valueKey,
      compactLabel: field?.name ?? valueKey,
      axis: (axes?.right.keys.includes(valueKey) ?? false) ? ('right' as const) : ('left' as const),
      chartType: seriesViewOptions?.[valueKey]?.chartType ?? 'area',
      showValues: seriesViewOptions?.[valueKey]?.showValues ?? false,
      color: seriesViewOptions?.[valueKey]?.color ?? getChartColor(idx),
      minValue: Math.min(...items.map((item) => item.values[valueKey] ?? Infinity), Infinity),
      maxValue: Math.max(...items.map((item) => item.values[valueKey] ?? -Infinity), -Infinity),
    };
  });

const getGroupedItemValuesByKey = (values: GroupedItemValues, key: string): number[] =>
  values.reduce<number[]>((acc, group) => {
    const value = getItemValueByKey(group.values, key);
    return value !== null ? [...acc, value] : acc;
  }, []);

const getItemValueByKey = (values: ItemValues, key: string): number | null => values[key] ?? null;

const getChartItemValuesByKey = (values: ChartItemValues, key: string): number[] => {
  if (isGroupedValues(values)) {
    return getGroupedItemValuesByKey(values, key);
  }
  const value = getItemValueByKey(values, key);
  return value !== null ? [value] : [];
};

const generateCombinedSeries = (
  valueKeys: string[],
  groupKey: string,
  records2: NestedList,
  fields: Field[],
  items: ChartItem[],
  axes?: AxisOptions,
  seriesViewOptions?: SeriesViewOptions,
): PrimaryGroupSeriesMetaData[] => {
  let seriesIdx = 0;
  return Object.entries(groupBy(records2, (record) => record[groupKey])).flatMap(
    ([groupValue, groupedRecords]) =>
      valueKeys.map((valueKey) => {
        const combinedKey = generateCombinedKey(groupValue, valueKey);
        const groupField = fields.find((field) => field.key === groupKey);
        const valueField = fields.find((field) => field.key === valueKey);
        const formattedValue = formatPropertyValue(groupedRecords[0][groupKey], {
          field: groupField,
        });
        return {
          key: combinedKey,
          compactLabel: valueKeys.length > 1 ? getFieldLabel(valueKey, fields) : formattedValue,
          label:
            valueKeys.length > 1
              ? `${groupField?.name ?? groupKey}: ${formattedValue} » ${valueField?.name ?? valueKey}`
              : `${groupField?.name ?? groupKey}: ${formattedValue}`,
          type: valueField?.type ?? 'Number',
          format: valueField?.format,
          axis:
            (axes?.right.keys.includes(valueKey) ?? false) ? ('right' as const) : ('left' as const),
          chartType: seriesViewOptions?.[valueKey]?.chartType ?? 'area',
          showValues: seriesViewOptions?.[valueKey]?.showValues ?? false,
          color: getChartColor(seriesIdx++),
          minValue: Math.min(
            ...items.flatMap(
              (item) => getChartItemValuesByKey(item.values, combinedKey) ?? Infinity,
            ),
            Infinity,
          ),
          maxValue: Math.max(
            ...items.flatMap(
              (item) => getChartItemValuesByKey(item.values, combinedKey) ?? -Infinity,
            ),
            -Infinity,
          ),
          primaryGroupLabel: getFieldLabel(groupKey, fields),
          ...(valueKeys.length > 1 ? { secondaryGroupLabel: formattedValue } : {}),
        };
      }),
  );
};

const getValuesMax = (values: ChartItemValues): number =>
  isArray(values)
    ? Math.max(...values.map(({ values }) => getValuesMax(values)), -Infinity)
    : Math.max(...Object.values(values).filter(notNil), -Infinity);

const getItemsMax = (items: ChartItem[]): number =>
  Math.max(...items.map((item) => getValuesMax(item.values) ?? -Infinity), -Infinity);

const getValuesMin = (values: ChartItemValues): number =>
  isArray(values)
    ? Math.min(...values.map(({ values }) => getValuesMin(values)), Infinity)
    : Math.min(...Object.values(values).filter(notNil), Infinity);

const getItemsMin = (items: ChartItem[]): number =>
  Math.min(...items.map((item) => getValuesMin(item.values) ?? Infinity), Infinity);

const findMax = (acc: number, item: { values: ChartItemValues }) =>
  Math.max(acc, getValuesMax(item.values));

const findMin = (acc: number, item: { values: ChartItemValues }) =>
  Math.min(acc, getValuesMin(item.values));

export const generateTimeseriesChartData: ChartDataGenerator<TimeSeriesData> = ({
  records,
  valueKeys,
  fields,
  axisGroupKey,
  seriesGroupKey,
  axes,
  seriesViewOptions,
}) => {
  const items = entries(groupBy(records, (record) => record[axisGroupKey]))
    .map(([axisGroupValue, records]) => {
      const date = new Date(axisGroupValue);

      return {
        dateValue: date,
        label: date.toLocaleDateString(),
        values:
          seriesGroupKey === undefined
            ? generateValues(valueKeys, records)
            : generateCombinedValues(valueKeys, seriesGroupKey, records),
      };
    })
    .filter((item) => isValid(item.dateValue))
    .sort((a, b) => {
      if (a.dateValue < b.dateValue) {
        return -1;
      }
      if (b.dateValue < a.dateValue) {
        return 1;
      }
      return 0;
    });

  const minValue = getItemsMin(items);
  const maxValue = getItemsMax(items);

  const series =
    seriesGroupKey === undefined
      ? generateSeries(valueKeys, fields, items, axes, seriesViewOptions)
      : generateCombinedSeries(
          valueKeys,
          seriesGroupKey,
          records,
          fields,
          items,
          axes,
          seriesViewOptions,
        );

  return {
    maxValue,
    minValue,
    series,
    items,
  };
};

export const generateGroupedTimeseriesChartData: ChartDataGenerator<GroupedTimeSeriesData> = ({
  records,
  valueKeys,
  fields,
  axisGroupKey,
  seriesGroupKey,
  groupKey,
  axes,
  seriesViewOptions,
}) => {
  if (seriesGroupKey === undefined) {
    throw new Error('Series group key missing');
  }

  if (groupKey === undefined) {
    throw new Error('Group key missing');
  }

  const items = entries(groupBy(records, (record) => record[axisGroupKey]))
    .map(([axisGroupValue, records]) => {
      const date = new Date(axisGroupValue);

      return {
        dateValue: date,
        label: date.toLocaleDateString(),
        values: generateGroupedCombinedValues(valueKeys, seriesGroupKey, records, groupKey),
      };
    })
    .filter((item) => isValid(item.dateValue))
    .sort((a, b) => {
      if (a.dateValue < b.dateValue) {
        return -1;
      }
      if (b.dateValue < a.dateValue) {
        return 1;
      }
      return 0;
    });

  const minValue = getItemsMin(items);
  const maxValue = getItemsMax(items);
  const series = generateCombinedSeries(
    valueKeys,
    seriesGroupKey,
    records,
    fields,
    items,
    axes,
    seriesViewOptions,
  );

  return {
    maxValue,
    minValue,
    series,
    items,
  };
};

export const generateBarChartData: ChartDataGenerator<CategoryData> = ({
  records,
  valueKeys,
  fields,
  axisGroupKey,
  seriesGroupKey,
  axes,
  seriesViewOptions,
}) => {
  const field = fields.find((field) => field.key === axisGroupKey);

  const items = Object.values(groupBy(flattenNestedList(records), (record) => record[axisGroupKey]))
    .map((records) => ({
      categoryValue: formatPropertyValue(records[0][axisGroupKey], { field }),
      label: getFieldLabel(axisGroupKey, fields),
      key: axisGroupKey,
      values:
        seriesGroupKey === undefined
          ? generateValues(valueKeys, records)
          : generateCombinedValues(valueKeys, seriesGroupKey, records),
    }))
    .sort((a, b) => {
      const amax = Math.max(...Object.values(a.values).filter(notNil));
      const bmax = Math.max(...Object.values(b.values).filter(notNil));
      return amax === bmax ? 0 : amax > bmax ? -1 : 1;
    });

  const maxValue = getItemsMax(items);
  const minValue = getItemsMin(items);

  const series =
    seriesGroupKey === undefined
      ? generateSeries(valueKeys, fields, items, axes, seriesViewOptions)
      : generateCombinedSeries(
          valueKeys,
          seriesGroupKey,
          records,
          fields,
          items,
          axes,
          seriesViewOptions,
        );

  return {
    maxValue,
    minValue,
    series,
    items,
  };
};

export const generateGroupedTimeSeriesData = (
  records: NestedList,
  visualisation: Visualisation,
  groups: Grouping[],
  fields: Field[],
  axes?: AxisOptions,
): GroupedChartData<TimeSeriesData | GroupedTimeSeriesData> => {
  if (visualisation.mainAxisKey === undefined) {
    throw new Error('Axis key missing');
  }

  const isStacked = visualisation.viewOptions?.stacked ?? false;
  const allBars = visualisation.valueKeys.every(
    (key) => visualisation.viewOptions?.series?.[key]?.chartType === 'bar',
  );
  if (groups.length === 3 && isStacked && allBars) {
    return {
      type: 'data',
      chartData: generateGroupedTimeseriesChartData({
        records: flattenNestedList(records),
        valueKeys: visualisation.valueKeys,
        fields,
        axisGroupKey: visualisation.mainAxisKey,
        seriesGroupKey: groups.at(-2)?.key,
        groupKey: groups.at(-3)?.key,
        axes,
        seriesViewOptions: visualisation.viewOptions?.series,
      }),
    };
  }

  if (groups.length > 2) {
    return records.map((item) => {
      if (item.$children === undefined) {
        throw new Error('Unexpected end of data');
      }
      const field = fields.find((field) => field.key === groups[0].key);
      return {
        type: 'group' as const,
        label: formatPropertyValue(item[groups[0].key], { field }),
        items: generateGroupedTimeSeriesData(
          item.$children,
          visualisation,
          groups.slice(1),
          fields,
          axes,
        ),
      };
    });
  }

  return {
    type: 'data',
    chartData: generateTimeseriesChartData({
      records: flattenNestedList(records),
      valueKeys: visualisation.valueKeys,
      fields,
      axisGroupKey: visualisation.mainAxisKey,
      seriesGroupKey: groups.at(-2)?.key,
      axes,
      seriesViewOptions: visualisation.viewOptions?.series,
    }),
  };
};

export const generateGroupedCategoryData = (
  records: NestedList,
  visualisation: Visualisation,
  groups: Grouping[],
  fields: Field[],
): GroupedChartData<CategoryData> => {
  if (visualisation.mainAxisKey === undefined) {
    throw new Error('Axis key missing');
  }

  if (groups.length > 2) {
    return records.map((item) => {
      if (item.$children === undefined) {
        throw new Error('Unexpected end of data');
      }
      const field = fields.find((field) => field.key === groups[0].key);
      return {
        type: 'group' as const,
        label: formatPropertyValue(item[groups[0].key], { field }),
        key: groups[0].key,
        items: generateGroupedCategoryData(item.$children, visualisation, groups.slice(1), fields),
      };
    });
  }

  return groups.length > 1
    ? {
        type: 'data',
        chartData: generateBarChartData({
          records: flattenNestedList(records),
          valueKeys: visualisation.valueKeys,
          fields,
          axisGroupKey: visualisation.mainAxisKey,
          seriesGroupKey: groups.at(-2)?.key ?? '',
          seriesViewOptions: visualisation.viewOptions?.series,
        }),
      }
    : {
        type: 'data',
        chartData: generateBarChartData({
          records: flattenNestedList(records),
          valueKeys: visualisation.valueKeys,
          fields,
          axisGroupKey: visualisation.mainAxisKey,
          seriesViewOptions: visualisation.viewOptions?.series,
        }),
      };
};

export const generateHistogramData = (
  records: any,
  visualisation: Visualisation,
): { data: HistogramDataItem[]; series: SingleSeriesMetaData } => {
  const viewOptions = visualisation.viewOptions?.series?.[visualisation.valueKeys[0]];
  const items = flattenNestedList(records).map((record) => ({
    values: { freq: Number(record.freq) },
  }));
  return {
    data: records,
    series: {
      key: 'freq',
      type: 'Integer',
      chartType: 'bar',
      axis: 'left',
      label: 'Count',
      compactLabel: 'Count',
      minValue: items.reduce(findMin, Infinity),
      maxValue: items.reduce(findMax, -Infinity),
      color: viewOptions?.color ?? getChartColor(0),
      showValues: viewOptions?.showValues ?? false,
    },
  };
};

type FilterCondition = {
  axis?: 'left' | 'right';
  chartType?: ChartType;
  primaryGroupLabel?: string;
  secondaryGroupLabel?: string;
};

const filterSeriesByCondition = (condition: FilterCondition) => (meta: SeriesMetaData) => {
  return (
    (condition.axis === undefined || meta.axis === condition.axis) &&
    (condition.chartType === undefined || meta.chartType === condition.chartType) &&
    (condition.primaryGroupLabel === undefined ||
      ('primaryGroupLabel' in meta && meta.primaryGroupLabel === condition.primaryGroupLabel)) &&
    (condition.secondaryGroupLabel === undefined ||
      ('secondaryGroupLabel' in meta && meta.secondaryGroupLabel === condition.secondaryGroupLabel))
  );
};

export const getMinValue = (data: GroupedChartData, condition: FilterCondition = {}): number =>
  getSeriesFromGroupedData(data, condition).reduce(
    (acc, { minValue }) => Math.min(acc, minValue),
    Infinity,
  );

export const getMaxValue = (data: GroupedChartData, condition: FilterCondition = {}): number =>
  getSeriesFromGroupedData(data, condition).reduce(
    (acc, { maxValue }) => Math.max(acc, maxValue),
    -Infinity,
  );

const getStackTotal = (values: ChartItemValues): number =>
  isArray(values)
    ? values.reduce((acc, { values }) => Math.max(acc, getStackTotal(values)), -Infinity)
    : Object.values(values)
        .filter(notNil)
        .reduce((acc, value) => acc + value, 0);

const pickValues = (values: ChartItemValues, keys: string[]): ChartItemValues =>
  isArray(values)
    ? values.map((item) => ({ ...item, values: pick(item.values, keys) }))
    : pick(values, keys);

export const getMaxStackedValue = (
  data: GroupedChartData,
  condition: FilterCondition = {},
): number => {
  if (isArray(data)) {
    return Math.max(...data.map((item) => getMaxStackedValue(item.items, condition)));
  }

  return Math.max(
    ...['area' as const, 'line' as const, 'bar' as const].map((chartType) => {
      const valueKeys = data.chartData.series
        .filter(filterSeriesByCondition({ ...condition, chartType }))
        .map(({ key }) => key);
      return Math.max(
        ...data.chartData.items.flatMap((item) =>
          getStackTotal(pickValues(item.values, valueKeys)),
        ),
      );
    }),
  );
};

export const getMinStackedValue = (
  data: GroupedChartData,
  condition: FilterCondition = {},
): number => {
  if (isArray(data)) {
    return Math.min(...data.map((item) => getMaxStackedValue(item.items, condition)));
  }

  return Math.min(
    ...['area' as const, 'line' as const, 'bar' as const].map((chartType) => {
      const valueKeys = data.chartData.series
        .filter(filterSeriesByCondition({ ...condition, chartType }))
        .map(({ key }) => key);
      return Math.min(
        ...data.chartData.items.flatMap((item) =>
          getStackTotal(pickValues(item.values, valueKeys)),
        ),
      );
    }),
  );
};

export const getValueKeys = (data: GroupedChartData, axis?: 'left' | 'right'): string[] =>
  getSeries(data)
    .filter((series) => axis === undefined || series.axis === axis)
    .map((series) => series.key);

const getSeriesFromGroupedData = (
  data: GroupedChartData,
  condition: FilterCondition = {},
): SeriesMetaData[] =>
  isArray(data)
    ? data.flatMap((item) => getSeriesFromGroupedData(item.items, condition))
    : data.chartData.series.filter(filterSeriesByCondition(condition));

export const getSeries = (
  data: GroupedChartData,
  condition: FilterCondition = {},
): SeriesMetaData[] => uniqBy(getSeriesFromGroupedData(data, condition), (series) => series.key);

export const getAxisType = (series: SeriesMetaData[]) => {
  const types = uniq(series.map(({ type }) => type));
  return types.length === 1 ? first(types) : 'Number';
};

export const getAxisFormat = (series: SeriesMetaData[]) => {
  const formats = uniq(series.map(({ format }) => format));
  return formats.length === 1 ? first(formats) : undefined;
};

export const getLeftRightAxisTypes = (series: SeriesMetaData[]) => {
  return {
    left: getAxisType(series.filter(({ axis }) => axis === 'left')),
    right: getAxisType(series.filter(({ axis }) => axis === 'right')),
  };
};

export const getLeftRightAxisFormats = (series: SeriesMetaData[]) => {
  return {
    left: getAxisFormat(series.filter(({ axis }) => axis === 'left')),
    right: getAxisFormat(series.filter(({ axis }) => axis === 'right')),
  };
};

export const getSeriesAxes = (series: SeriesMetaData[]) =>
  uniq(series.map((series) => series.axis)).sort();

export const getSeriesTotalsFormat = (series: SeriesMetaData[]) => {
  const type = first(series)?.type ?? 'Number';
  const format = series.every(({ format }) => format === first(series)?.format)
    ? first(series)?.format
    : undefined;

  return {
    type,
    format,
  };
};

export const flattenGroupedChartData = <T extends ChartData>(data: GroupedChartData<T>): T[] =>
  isArray(data) ? data.flatMap((item) => flattenGroupedChartData(item.items)) : [data.chartData];

export const getChartCount = (data: GroupedChartData): number =>
  flattenGroupedChartData(data).length;

import { Fragment, useEffect, useMemo, useRef, useState, Suspense, lazy, useCallback } from 'react';
import { first, isEqual, isNil } from 'lodash';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { AnalyticsContextProvider, useTrackEvent } from '@/lib/analytics';
import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { useTitle } from '@/lib/hooks/use-title';
import { useKonstaabel } from '@/lib/hooks/use-konstaabel';
import { useLayoutContext } from '@/components/layout/layout-context';
import { useKeyPress } from '@/lib/hooks/use-key-press';
import { buildChatCell, getConversationCells, isConversationCell, isChatCell } from '@/core/cell';
import { isEmptyExploration } from '@/core/exploration';
import { notNil } from '@/lib/utils';
import { HideInEmbedded } from '@/components/layout/hide-in-embedded';

import { useDirtyContext } from '../dirty-context';
import { ScrollToSelected } from './scroll-to-selected';
import { ExplorationSearchModal } from '../exploration-search';
import { ExplorationCell } from './exploration-cell';
import { Exploration, Cell, CanvasState } from '../types';
import { getAbsoluteCellIndex, getHashParameter, getVariableDefinitions } from '../utils';
import { Sidebar } from './sidebar';
import { useExplorationContext } from './exploration-context';
import { CellDropZone } from './exploration-layout/cell-dropzone';
import { getCellRows, measureCells } from './exploration-layout/utils';
import { CellInputTrigger } from './cell-input/cell-input-trigger';
import { CellInput } from './cell-input';

const InfiniteCanvas = lazy(() => import('../components/canvas'));

import { getCellIndex } from './utils';

import { ExplorationHeader } from './header';

import styles from './exploration.module.scss';
import canvasStyles from '../components/canvas/canvas.module.scss';
import layoutStyles from './exploration-layout/exploration-layout.module.scss';
import cellInputStyles from './cell-input/cell-input.module.scss';

interface ExplorationViewProps {
  accountId: string;
}

export const ExplorationView = (props: ExplorationViewProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const selectedCellRef = useRef<HTMLDivElement | null>(null);
  const cellsRef = useRef<HTMLDivElement | null>(null);

  const [explorationRowHeights, setExplorationRowHeights] = useState<(number | undefined)[]>([]);
  const [showDataCatalog, setShowDataCatalog] = useState(false);
  const location = useLocation();

  const { confirmUnsavedChangesIfNeeded } = useDirtyContext();

  useKonstaabel();

  const {
    exploration,
    parameters,
    selectedCell,
    isEditorOpen,
    setExploration,
    scrollToId,
    closeEditor,
    addCells,
    openEditor,
    scrollToCell,
    cellCount,
    canvasState,
    setCanvasState,
    selectCell,
    deselectCell,
    copiedCell,
    hasCopiedCell,
    removeCopiedCell,
    newCellIndex,
    setNewCellIndex,
    setConversationDraft,
  } = useExplorationContext();

  const selectedCellIndex =
    selectedCell !== null ? getCellIndex(selectedCell.id, exploration) : null;

  const setRowHeightOverride = (index: number, height: number | undefined) => {
    setExplorationRowHeights((prev) => {
      const next = [...prev];
      next[index] = height;
      return next;
    });
  };

  const trackEvent = useTrackEvent();
  const screenSize = useScreenSize();
  const { isRightSidebarOpen, isEmbeddedMode, toggleRightSidebar } = useLayoutContext();

  useKeyPress('Escape', () => isEditorOpen && closeEditor(), { includeInputs: true });

  useTitle(exploration.name);

  useEffect(() => {
    setNewCellIndex(null);
    trackEvent('Exploration Opened', {
      explorationId: exploration.explorationId,
      explorationSourceId: exploration.options?.explorationSourceId,
    });
  }, [exploration.options?.explorationSourceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollIntoView = (elem?: HTMLElement | null) =>
    elem?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (scrollToId !== null && scrollToRef.current !== null) {
      // make sure to scroll to the top for the first cell
      if (scrollToId === exploration.view.cells.at(0)?.id) {
        scrollIntoView(containerRef.current);
      } else {
        scrollIntoView(scrollToRef.current);
      }
      scrollToCell(null);
    }
  }, [scrollToCell, exploration, scrollToId]);

  useEffect(() => {
    const scrollToCellId = getHashParameter(location.hash, 'cell');
    if (scrollToCellId !== null) {
      selectCell(scrollToCellId);
      scrollToCell(scrollToCellId);
    }
  }, [location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  const addSection = useCallback(
    (index = cellCount) => {
      confirmUnsavedChangesIfNeeded({
        onConfirm: () => {
          closeEditor();
          setNewCellIndex(index);
        },
      });
    },
    [confirmUnsavedChangesIfNeeded, closeEditor, cellCount, setNewCellIndex],
  );

  const emptyExploration = isEmptyExploration(exploration);

  const renderExplorationList = (index: number) => (
    <AnalyticsContextProvider
      properties={{
        intent: 'new block',
        exploration,
        atIndex: index,
      }}>
      <ExplorationSearchModal
        exploration={!emptyExploration ? exploration : undefined}
        selectIndividualCells={!emptyExploration}
        copiedCell={copiedCell}
        onClose={() => setShowDataCatalog(false)}
        onClickExploration={
          emptyExploration
            ? undefined
            : (exploration) => {
                handleClickExploration(exploration, index);
                setNewCellIndex(null);
                setShowDataCatalog(false);
              }
        }
        onPasteExploration={() => handleCellPaste(index)}
      />
    </AnalyticsContextProvider>
  );

  const isDesktopView = screenSize.breakpoint > Breakpoint.md;
  const rows = useMemo(() => getCellRows(exploration.view.cells), [exploration.view.cells]);

  useEffect(
    () => setCanvasState({ isCanvasView: false }),
    [setCanvasState, exploration.options?.explorationSourceId],
  );

  const persistCanvas = (state: CanvasState) => {
    if (!isEqual(state, exploration.view.canvas)) {
      setExploration({ ...exploration, view: { ...exploration.view, canvas: state } });
    }
  };

  const addConversationCell = (searchQuery: string, index: number) => {
    if (exploration.view.cells.length === 0 && isRightSidebarOpen) {
      toggleRightSidebar(false);
    }

    const cell = buildChatCell(searchQuery);
    addCells([cell], index);
    setConversationDraft(cell.conversationId, searchQuery);
    setNewCellIndex(null);
  };

  const handleViewChange = (value: string) => {
    setCanvasState({
      isCanvasView: value === 'canvas',
      nodes: cellsRef.current !== null ? measureCells(cellsRef.current) : undefined,
    });
  };

  const handleClickExploration = (selectedExploration: Exploration, index: number) => {
    const { cells } = addCells(selectedExploration.view.cells, index);
    const cellId = cells.at(0)?.id;

    if (cellId !== undefined) {
      selectCell(cellId);
      scrollToCell(cellId);
    }

    setNewCellIndex(null);
    trackEvent('Exploration Cells Added', {
      explorationId: exploration.explorationId,
      selectedExploration: selectedExploration.explorationId,
      selectedName: selectedExploration.name,
      atIndex: index,
    });
  };

  const handleCellPaste = (cellIndex: number) => {
    if (isNil(copiedCell)) {
      return;
    }

    const { cells } = addCells([copiedCell.cell, ...copiedCell.dependencies], cellIndex);
    const cellId = cells.at(0)?.id;

    if (cellId === undefined) {
      return;
    }

    selectCell(cellId);
    scrollToCell(cellId);
    setNewCellIndex(null);
    setShowDataCatalog(false);
    removeCopiedCell();

    trackEvent('Exploration Cell Pasted', {
      explorationId: exploration.explorationId,
      atIndex: cellIndex,
      cellId: copiedCell.cell.id,
      numDependencies: copiedCell.dependencies.length,
    });
  };

  const belongsToLastResponseGroup = (cellToCheck: Cell) => {
    if (!isConversationCell(cellToCheck)) {
      return false;
    }

    const cells = getConversationCells(exploration.view.cells, cellToCheck.conversationId);
    const group: string[] = [];

    for (const cell of cells.slice().reverse()) {
      const chatCell = isChatCell(cell);
      if (!chatCell) {
        group.push(cell.id);
      } else if (chatCell && group.length === 0) {
        continue;
      } else if (chatCell && group.length) {
        break;
      }
    }

    return group.includes(cellToCheck.id);
  };

  const precededByConversationCell = (cell: Cell, rowIndex: number) => {
    if (!isConversationCell(cell)) {
      return false;
    }

    const prevCell = rowIndex > 0 ? rows[rowIndex - 1][rows[rowIndex - 1].length - 1] : null;

    return (
      notNil(prevCell) &&
      isConversationCell(prevCell) &&
      prevCell.conversationId === cell.conversationId
    );
  };

  const followedByConversationCell = (cell: Cell, rowIndex: number) => {
    if (!isConversationCell(cell)) {
      return false;
    }

    const nextCell = rowIndex < rows.length - 1 ? rows[rowIndex + 1][0] : null;

    return (
      notNil(nextCell) &&
      isConversationCell(nextCell) &&
      nextCell.conversationId === cell.conversationId
    );
  };

  return (
    <div className={styles.explorationContent} ref={containerRef}>
      <div className={styles.splitView}>
        <div className={styles.main}>
          <HideInEmbedded>
            {emptyExploration ? null : <ExplorationHeader handleViewChange={handleViewChange} />}
          </HideInEmbedded>

          <ScrollToSelected
            direction="up"
            cellIndex={selectedCellIndex}
            cellRef={selectedCellRef}
            onClick={() => scrollToCell(selectedCell?.id ?? null)}
          />

          {canvasState.isCanvasView ? (
            <Suspense fallback={<div className={canvasStyles.placeholder} />}>
              <InfiniteCanvas
                onChange={persistCanvas}
                onSelectionChange={(id) => {
                  if (id !== undefined) {
                    selectCell(id);
                  } else {
                    deselectCell();
                  }
                }}
              />

              <div
                className={classNames([
                  canvasStyles.canvasCta,
                  newCellIndex !== 0 && canvasStyles.canvasCtaBackground,
                ])}>
                {newCellIndex === 0 ? (
                  <CellInput
                    onAsk={(search) => addConversationCell(search, 0)}
                    onShowCatalog={() => setShowDataCatalog(true)}
                    onClickExploration={(exploration) => handleClickExploration(exploration, 0)}
                    onClose={() => setNewCellIndex(null)}
                  />
                ) : (
                  <CellInputTrigger
                    compact={false}
                    showPaste={hasCopiedCell}
                    onClick={() => addSection(0)}
                    onPaste={() => hasCopiedCell && handleCellPaste(0)}
                  />
                )}
              </div>
              {showDataCatalog && newCellIndex === 0 && renderExplorationList(0)}
            </Suspense>
          ) : (
            <div
              ref={cellsRef}
              className={classNames(emptyExploration && cellInputStyles.withTitle)}>
              {isEditorOpen && <div className={styles.overlay} onClick={closeEditor} />}

              {emptyExploration ? (
                <CellInput
                  emptyExploration={emptyExploration}
                  onAsk={(search) => addConversationCell(search, 0)}
                  onShowCatalog={() => setShowDataCatalog(true)}
                  onClickExploration={(exploration) => handleClickExploration(exploration, 0)}
                  onClose={() => setNewCellIndex(null)}
                />
              ) : newCellIndex !== 0 ? (
                <CellDropZone index={0} addBefore mergeIntoRow={false}>
                  <HideInEmbedded renderHidden>
                    <CellInputTrigger
                      compact={false}
                      showPaste={hasCopiedCell}
                      onClick={() => addSection(0)}
                      onPaste={() => hasCopiedCell && handleCellPaste(0)}
                    />
                  </HideInEmbedded>
                </CellDropZone>
              ) : (
                <CellInput
                  onAsk={(search) => addConversationCell(search, 0)}
                  onShowCatalog={() => setShowDataCatalog(true)}
                  onClickExploration={(exploration) => handleClickExploration(exploration, 0)}
                  onClose={() => setNewCellIndex(null)}
                />
              )}

              {showDataCatalog &&
                (newCellIndex === 0 || newCellIndex === null) &&
                renderExplorationList(0)}
              {rows.map((cells, rowIndex) => {
                const nextRowCellIndex = getAbsoluteCellIndex(rows, rowIndex + 1, 0);
                const isLastRow = rowIndex === rows.length - 1;
                const isLastConversationRow = !followedByConversationCell(cells[0], rowIndex);
                const conversationCellPrecedes = precededByConversationCell(cells[0], rowIndex);
                const isLastConversationReply = belongsToLastResponseGroup(cells[0]);
                const rowKey = first(cells)?.viewOptions?.rowId ?? first(cells)?.id ?? rowIndex;

                return (
                  <Fragment key={rowKey}>
                    <div
                      className={classNames([
                        layoutStyles.explorationRow,
                        conversationCellPrecedes && styles.conversationLine,
                        (isLastConversationReply || isLastConversationRow) &&
                          styles.conversationActive,
                      ])}>
                      {cells.map((cell, i) => {
                        const cellIndex = getAbsoluteCellIndex(rows, rowIndex, i);
                        const isSelected = cell.id === selectedCell?.id;
                        return (
                          <Fragment key={cell.id}>
                            <ExplorationCell
                              index={cellIndex}
                              accountId={props.accountId}
                              exploration={exploration}
                              parameters={parameters}
                              cell={cell}
                              selected={isSelected}
                              height={cell.viewOptions?.height}
                              rowHeightOverride={explorationRowHeights[rowIndex]}
                              setRowHeightOverride={(height) =>
                                setRowHeightOverride(rowIndex, height)
                              }
                              ref={(element) => {
                                if (cell.id === scrollToId) {
                                  scrollToRef.current = element;
                                }
                                if (isSelected) {
                                  selectedCellRef.current = element;
                                }
                              }}
                              variables={getVariableDefinitions(exploration)}
                              isResizable={!isEmbeddedMode}
                              isCollapsible={cells.length === 1 || !isDesktopView}
                              isLastConversationReply={belongsToLastResponseGroup(cell)}
                              onSelectCell={() =>
                                !isSelected && isDesktopView && openEditor(cell.id)
                              }
                            />
                            {i < cells.length - 1 && !isConversationCell(cell) && (
                              <CellDropZone
                                index={cellIndex}
                                vertical
                                mergeIntoRow
                                className={layoutStyles.cellSeparator}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>

                    <HideInEmbedded renderHidden>
                      {newCellIndex !== nextRowCellIndex && isLastConversationRow ? (
                        <CellDropZone index={nextRowCellIndex - 1} mergeIntoRow={false}>
                          <CellInputTrigger
                            compact={!isLastRow}
                            showPaste={hasCopiedCell}
                            onClick={() => addSection(nextRowCellIndex)}
                            onPaste={() => hasCopiedCell && handleCellPaste(nextRowCellIndex)}
                          />
                        </CellDropZone>
                      ) : newCellIndex === nextRowCellIndex ? (
                        <CellInput
                          onAsk={(search) => addConversationCell(search, nextRowCellIndex)}
                          onShowCatalog={() => setShowDataCatalog(true)}
                          onClickExploration={(exploration) =>
                            handleClickExploration(exploration, nextRowCellIndex)
                          }
                          onClose={() => setNewCellIndex(null)}
                        />
                      ) : null}
                    </HideInEmbedded>

                    {showDataCatalog &&
                      newCellIndex === nextRowCellIndex &&
                      renderExplorationList(nextRowCellIndex)}
                  </Fragment>
                );
              })}
              <ScrollToSelected
                direction="down"
                cellIndex={selectedCellIndex}
                cellRef={selectedCellRef}
                onClick={() => scrollToCell(selectedCell?.id ?? null)}
              />
            </div>
          )}
        </div>
        <HideInEmbedded>
          {isRightSidebarOpen && !emptyExploration && <Sidebar onAddSection={() => addSection()} />}
        </HideInEmbedded>
      </div>
    </div>
  );
};

import { get, last } from 'lodash';

import { getNodes, useExplorationDataQuery } from '@/graphql';
import { GenericFallback } from '@/lib/error';
import { useQueryLoadCondition } from '@/lib/hooks';
import { flattenNestedList } from '@/explore/grouping';
import {
  Pipeline,
  Field,
  QueryVariables,
  Visualisation,
  ValueClickHandler,
  isAggregatedVisualisation,
} from '@/explore/types';
import { filterVariablesForPipeline } from '@/explore/utils';
import { useMetadataContext } from '@/explore/metadata-context';
import { getDereferencedPipelineFields, precisionToTimeInterval } from '@/explore/pipeline/state';
import { useExplorationCellContext } from '@/explore/exploration/exploration-cell-context';
import { sortDateGroupingsLast } from '@/explore/utils/grouping';
import { useAccountContext, useAccountTimezone } from '@/lib/accounts/context';

import { dereferencePipeline } from '@/explore/pipeline/utils';

import { useExplorationContext } from '@/explore/exploration/exploration-context';

import { isBigNumberWithComparison } from '../utils';
import { GroupedTimeSeriesChart } from '../../charts/timeseries-chart/grouped-timeseries-chart';
import { DataWarning } from '../data-warning';
import { getChartType, getQueryForHistogram, getQueryForVisualisation } from '../utils';
import { Histogram } from '../../charts/histogram';
import { VisualisationLoader } from '..';
import {
  generateGroupedCategoryData,
  generateGroupedTimeSeriesData,
  generateHistogramData,
} from '../../charts/grouped-chart/utils';
import { BigNumber } from '../../charts/big-number';
import { GroupedHorizontalBarChart } from '../../charts/horizontal-bar-chart/grouped-horizontal-chart';

import styles from './visualisation-graph.module.scss';

interface VisualisationGraphProps {
  visualisation: Visualisation;
  pipeline: Pipeline;
  variables: QueryVariables;
  fields: Field[];
  onValueClick?: ValueClickHandler;
  onStackingChange?: (isStacked: boolean) => void;
  isInView: boolean;
  isResized: boolean;
}

export const VisualisationGraph = (props: VisualisationGraphProps) => {
  const { visualisation, fields, variables, isResized, onValueClick, onStackingChange } = props;
  const { account } = useAccountContext();
  const { exploration } = useExplorationContext();

  const chartType = getChartType(fields, visualisation);
  const {
    pipeline,
    sort,
    limit,
    groups: queryGroups,
  } = chartType === 'histogram'
    ? getQueryForHistogram(props.pipeline, visualisation)
    : getQueryForVisualisation(props.pipeline, fields, visualisation);

  const pipelineVariables = filterVariablesForPipeline(pipeline, props.variables);

  const timezone = useAccountTimezone();
  const { models, metrics: metrics } = useMetadataContext();
  const { isCollapsed, isTableVisible } = useExplorationCellContext();
  const [onCompleted, onError, skip] = useQueryLoadCondition(props.isInView, !isCollapsed);

  const visualisationPipeline = dereferencePipeline(pipeline, exploration);
  const { data, error } = useExplorationDataQuery({
    variables: {
      baseModelId: visualisationPipeline.baseModelId,
      accountId: account.accountId,
      pipeline: visualisationPipeline.operations,
      sort,
      first: limit,
      variables: pipelineVariables,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    skip,
    onCompleted,
    onError,
  });

  if (error !== undefined) {
    return <GenericFallback details={error.message} />;
  }

  const records = getNodes(data?.account?.query);
  const loaded = data?.account?.query !== undefined;
  const isBigNumber = chartType === 'single-value';
  const visRecords = queryGroups.length > 1 ? records : flattenNestedList(records);
  const hasNextPage = data?.account?.query?.pageInfo.hasNextPage ?? false;
  const bigNumberFieldKey = visualisation.viewOptions?.bigNumber?.key;
  const axesOptions = visualisation.viewOptions?.axes;
  const isBigNumberShown = bigNumberFieldKey !== undefined;
  const isNotFullData = hasNextPage && chartType !== 'single-value';

  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, {
    models,
    variables,
    metrics,
  });

  const mainAxisField = visualisationFields.find(
    (field) => field.key === visualisation.mainAxisKey,
  );

  const renderBigNumber = (compactMode = false, loading = false) => {
    const bigNumberField = isBigNumberShown
      ? visualisationFields.find((field) => field.key === bigNumberFieldKey)
      : undefined;

    if (bigNumberField === undefined) {
      return null;
    }

    if (isBigNumberWithComparison(visualisation.viewOptions?.bigNumber)) {
      const bigNumberComparisonKey = visualisation.viewOptions?.bigNumber?.comparisonKey;
      const bigNumberComparisonField =
        bigNumberComparisonKey !== undefined
          ? visualisationFields.find((field) => field.key === bigNumberComparisonKey)
          : undefined;

      return (
        <>
          {isNotFullData ? <DataWarning /> : null}
          <BigNumber
            field={bigNumberField}
            comparisonField={bigNumberComparisonField}
            direction={visualisation.viewOptions?.bigNumber?.comparisonDirection}
            showLabel={visualisation.viewOptions?.bigNumber?.showComparisonLabel}
            comparisonType={visualisation.viewOptions?.bigNumber?.comparisonType}
            data={visRecords}
            compact={compactMode}
            loading={loading}
          />
        </>
      );
    }

    return (
      <BigNumber field={bigNumberField} data={visRecords} compact={compactMode} loading={loading} />
    );
  };

  if (!loaded) {
    if (isBigNumber) {
      return renderBigNumber(true, true);
    }
    return <VisualisationLoader isResized={!isTableVisible && isResized} />;
  }

  const renderHistogram = () => {
    const field = fields.find(
      ({ key }) => key === get(last(visualisationPipeline.operations)?.parameters, 'key'),
    );

    if (field === undefined) {
      throw new Error('Visualisation field not found');
    }

    const data = generateHistogramData(records, visualisation);

    return (
      <Histogram
        data={data}
        categoryLabel={field.name}
        field={field}
        valueLabel={''}
        isResized={isResized}
        onValueClick={onValueClick}
      />
    );
  };

  const renderTimeSeriesChart = () => {
    const data = generateGroupedTimeSeriesData(
      records,
      visualisation,
      queryGroups,
      visualisationFields,
      axesOptions,
    );

    const precision = isAggregatedVisualisation(visualisation)
      ? (precisionToTimeInterval(
          sortDateGroupingsLast(visualisation.aggregation.groups).at(-1)?.precision,
          variables,
        ) ?? null)
      : (precisionToTimeInterval(mainAxisField?.precision, variables) ?? null);

    return (
      <>
        {isNotFullData ? <DataWarning /> : null}
        {isBigNumberShown ? <div className={styles.chartOverlay}>{renderBigNumber()}</div> : null}
        <GroupedTimeSeriesChart
          data={data}
          grouping={queryGroups}
          aggPeriod={precision}
          stacked={visualisation.viewOptions?.stacked === true}
          setStacked={onStackingChange}
          onValueClick={onValueClick}
          hideGrid={false}
          timezone={timezone}
          isResized={props.isResized}
          isTableVisible={isTableVisible}
        />
      </>
    );
  };

  const renderBarChart = () => {
    const data = generateGroupedCategoryData(
      records,
      visualisation,
      queryGroups,
      visualisationFields,
    );

    // Check if all values are integers to round the axis values
    const areAllValuesIntegers = visualisation.valueKeys
      .map((key) => visualisationFields.find((field) => field.key === key)!)
      .every((field) => field.type === 'Integer');

    return (
      <GroupedHorizontalBarChart
        data={data}
        grouping={queryGroups}
        stacked={visualisation.viewOptions?.stacked === true}
        setStacked={onStackingChange}
        onValueClick={onValueClick}
        roundTickValues={areAllValuesIntegers}
        isResized={props.isResized}
        isTableVisible={isTableVisible}
        isNotFullData={isNotFullData}
      />
    );
  };

  switch (chartType) {
    case 'single-value':
      return renderBigNumber();
    case 'grouped-timeseries':
      return renderTimeSeriesChart();
    case 'bar':
      return renderBarChart();
    case 'histogram':
      return renderHistogram();
    default:
      return null;
  }
};

import React, { TouchEventHandler, useMemo } from 'react';
import { first, isArray } from 'lodash';

import { ScaleLinear, ScaleTime } from 'd3-scale';

import { Bar, Line } from '@visx/shape';
import { GridColumns, GridRows } from '@visx/grid';
import { AxisLeft, AxisRight } from '@visx/axis';
import { getStringWidth } from '@visx/text';
import { common, model } from '@gosupersimple/types';

import { notNil } from '@/lib/utils';
import { getFormattedDateForPeriod } from '@/lib/date';
import { ChartType, TimeAggregationPeriod, ValueClickHandler } from '@/explore/types';
import { formatPropertyValue } from '@/explore/utils/format';

import { ChartTooltip, HighlightedData, renderGridColumns } from '../common';
import {
  getHighlightedTickValues,
  getScaleTimeBandwidth,
  getTickValues,
  GroupAxisHeight,
} from './utils';
import {
  GroupedTimeSeriesData,
  isGroupedTimeSeriesData,
  TimeSeriesData,
} from '../grouped-chart/types';
import { AreaStack } from './area-stack';
import { LineStack } from './line-stack';
import { BarStack } from './bar-stack';
import { StackValues } from './stack-values';
import {
  getLeftRightAxisFormats,
  getLeftRightAxisTypes,
  getSeriesAxes,
} from '../grouped-chart/utils';

import commonStyles, {
  gridLineColor,
  gridHighlightedLineColor,
  hoverLineColor,
} from '../charts.module.scss';

const NumYTicks = 7;

export type StackedChartData = {
  [key: string]: number | Date;
  date: Date;
}[];

export interface ChartColor {
  fill: string;
  highlight: string;
}

const buildTooltipData = (
  highlightedData: HighlightedData | null,
  data: TimeSeriesData,
  timezone: string,
  aggPeriod: TimeAggregationPeriod | null,
): TooltipData | null => {
  if (highlightedData === null) {
    return null;
  }

  const item = data.items.find(
    ({ dateValue }) => dateValue.getTime() === highlightedData.date.getTime(),
  );

  if (item === undefined) {
    return null;
  }

  const points = data.series.map(({ key, label, type, format, axis, chartType, color }) => ({
    label,
    value: item.values[key] ?? null,
    type,
    format,
    date: item.dateValue,
    color,
    axis,
    chartType,
  }));

  return {
    date: highlightedData.date,
    left: highlightedData.point.x,
    top: highlightedData.point.y,
    timezone,
    aggPeriod: aggPeriod,
    points,
  };
};

const buildGroupedTooltipData = (
  highlightedData: HighlightedData | null,
  data: GroupedTimeSeriesData,
  timezone: string,
  aggPeriod: TimeAggregationPeriod | null,
): GroupedTooltipData | null => {
  if (highlightedData === null) {
    return null;
  }

  const item = data.items.find(
    ({ dateValue }) => dateValue.getTime() === highlightedData.date.getTime(),
  );

  if (item === undefined) {
    return null;
  }

  const groups = item.values
    .filter(({ group }) => group === highlightedData.group)
    .map(({ group, values }) => ({
      label: `${group}: ${Object.values(values)
        .filter(notNil)
        .reduce((sum, value) => sum + value, 0)}`,
      points: data.series
        .filter((series) => series.axis === highlightedData.axis)
        .map(({ key, label, type, format, axis, chartType, color }) => ({
          label,
          value: values[key] ?? null,
          type,
          format,
          date: item.dateValue,
          color,
          axis,
          chartType,
        })),
    }));

  return {
    date: highlightedData.date,
    left: highlightedData.point.x,
    top: highlightedData.point.y,
    timezone,
    aggPeriod: aggPeriod,
    groups,
  };
};

interface StackedTimeSeriesChartProps {
  width: number;
  height: number;
  data: TimeSeriesData | GroupedTimeSeriesData;
  dateScale: ScaleTime<number, number>;
  valueScales: {
    left: ScaleLinear<number, number>;
    right: ScaleLinear<number, number>;
  };
  aggPeriod: TimeAggregationPeriod | null;
  highlightedData: HighlightedData | null;
  groupKey: string | undefined;
  onMouseMove?: (event: MouseEvent, group?: string, axis?: 'left' | 'right') => void;
  onTouchMove?: TouchEventHandler<Element>;
  onMouseLeave?: () => void;
  onValueClick?: ValueClickHandler;
  hideGrid: boolean;
  timezone: string;
}

export const StackedTimeSeriesChart = (props: StackedTimeSeriesChartProps) => {
  const {
    width,
    height,
    data,
    dateScale,
    valueScales,
    aggPeriod,
    highlightedData,
    groupKey,
    onMouseMove,
    onTouchMove,
    onMouseLeave,
    onValueClick,
    hideGrid,
    timezone,
  } = props;

  const isGrouped = isGroupedTimeSeriesData(data);
  const hasOnlyBars = data.series.every(({ chartType }) => chartType === 'bar');
  const hasBars = hasOnlyBars || data.series.some(({ chartType }) => chartType === 'bar');

  const tooltipData: TooltipData | GroupedTooltipData | null = useMemo(
    () =>
      isGrouped
        ? buildGroupedTooltipData(highlightedData, data, timezone, aggPeriod)
        : buildTooltipData(highlightedData, data, timezone, aggPeriod),
    [isGrouped, highlightedData, data, timezone, aggPeriod],
  );

  const { axes, axisTypes, axisFormats } = useMemo(
    () => ({
      axes: getSeriesAxes(data.series),
      axisTypes: getLeftRightAxisTypes(data.series),
      axisFormats: getLeftRightAxisFormats(data.series),
    }),
    [data.series],
  );

  const formatLeftAxisValue = (n: number) =>
    formatPropertyValue(n, {
      type: axisTypes.left,
      format: axisFormats.left,
      style: 'compact',
    });

  const formatRightAxisValue = (n: number) =>
    formatPropertyValue(n, {
      type: axisTypes.right,
      format: axisFormats.right,
      style: 'compact',
    });

  const yAxisWidths = {
    left: Math.max(
      ...valueScales.left
        .ticks(NumYTicks)
        .map((i) => formatLeftAxisValue(i.valueOf()))
        .map((i) => getStringWidth(i) ?? 0),
    ),
  };

  const domainSizes = {
    left: valueScales.left.domain()[1] - valueScales.left.domain()[0],
    right: valueScales.right.domain()[1] - valueScales.right.domain()[0],
  };

  // Default to right as main axis in case no series on left axis
  const primaryValueScale = domainSizes.left > 0 ? valueScales.left : valueScales.right;

  // Force right axis ticks to align with primary axis ticks
  const rightAxisTicks = primaryValueScale
    .ticks(NumYTicks)
    .map((tick) => valueScales.right.invert(primaryValueScale(tick)));

  const renderBarStacks = (chartData: TimeSeriesData) => {
    const width = dateScale.range()[1];
    const barGroupWidth = width / chartData.items.length;
    const barGroupInnerWidth = (width / chartData.items.length) * 0.7;
    const barWidth = barGroupInnerWidth / axes.length;
    const barInnerWidth = barWidth * 0.8;

    return axes.map((axis, idx) => {
      const keys = chartData.series
        .filter((s) => s.chartType === 'bar' && s.axis === axis)
        .map(({ key }) => key);

      const offset = -(barGroupInnerWidth / 2) + idx * barWidth + barWidth / 2;
      const axisDateScale = dateScale
        .copy()
        .range([dateScale.range()[0] + offset, dateScale.range()[1] + offset]);

      return (
        <React.Fragment key={`bar-stack-${axis}`}>
          <BarStack
            keys={keys}
            data={chartData}
            valueScale={valueScales[axis]}
            dateScale={axisDateScale}
            offset={-(barGroupWidth / 2)}
            barWidth={barInnerWidth}
          />
          <StackValues
            keys={keys}
            data={chartData}
            valueScale={valueScales[axis]}
            dateScale={axisDateScale}
            bar
          />
        </React.Fragment>
      );
    });
  };

  const renderGroupedBarStacks = (chartData: GroupedTimeSeriesData) => {
    const width = dateScale.range()[1];
    const barGroupWidth = width / chartData.items.length;
    const barGroupInnerWidth = (width / chartData.items.length) * 0.7;
    const groups = chartData.items[0].values.map((v) => v.group);
    const numStacks = axes.length * groups.length;
    const barWidth = barGroupInnerWidth / numStacks;
    const barInnerWidth = barWidth * 0.8;

    return axes.map((axis, axisIdx) => {
      const keys = chartData.series
        .filter((s) => s.chartType === 'bar' && s.axis === axis)
        .map(({ key }) => key);

      const groups = isGrouped ? chartData.items[0].values.map((v) => v.group) : [];

      return groups.map((group, groupIdx) => {
        const idx = groupIdx + axisIdx * groups.length;
        const offset = -(barGroupInnerWidth / 2) + idx * barWidth + barWidth / 2;
        const axisDateScale = dateScale
          .copy()
          .range([dateScale.range()[0] + offset, dateScale.range()[1] + offset]);
        const chartData: TimeSeriesData = {
          ...data,
          items: data.items.map((item) => {
            const groupValues =
              (isArray(item.values)
                ? item.values.find((v) => v.group === group)?.values
                : item.values) ?? {};

            return {
              dateValue: item.dateValue,
              label: item.label,
              values: groupValues,
            };
          }),
        };

        return (
          <React.Fragment key={`bar-stack-${axis}-${group}`}>
            <BarStack
              keys={keys}
              data={chartData}
              valueScale={valueScales[axis]}
              dateScale={axisDateScale}
              offset={-(barGroupWidth / 2)}
              barWidth={barInnerWidth}
              group={group}
              onMouseMove={(event) => onMouseMove?.(event.nativeEvent, group, axis)}
              onMouseLeave={onMouseLeave}
            />
            <StackValues
              keys={keys}
              data={chartData}
              valueScale={valueScales[axis]}
              dateScale={axisDateScale}
              bar
            />
          </React.Fragment>
        );
      });
    });
  };

  const renderHighlightedPeriod = () => {
    if (!highlightedData) {
      return null;
    }

    const bandwidth = getScaleTimeBandwidth(dateScale, aggPeriod);

    return (
      <rect
        x={dateScale(highlightedData.date) - bandwidth / 2}
        y={0}
        width={bandwidth}
        height={height}
        fill={gridLineColor}
        pointerEvents="none"
      />
    );
  };

  const renderHighlightedDate = () => {
    if (!highlightedData) {
      return null;
    }

    return (
      <>
        {hasOnlyBars ? null : (
          <Line
            from={{ x: dateScale(highlightedData.date), y: 0 }}
            to={{ x: dateScale(highlightedData.date), y: height }}
            stroke={hoverLineColor}
            strokeWidth={1}
            pointerEvents="none"
            strokeDasharray="5,2"
          />
        )}
        {tooltipData !== null && isGroupedTooltipData(tooltipData)
          ? null
          : tooltipData?.points.map((point, i) => {
              if (point.date === null || point.chartType === 'bar') {
                return null;
              }

              const value = Number(
                tooltipData?.points
                  .slice(i)
                  .filter(({ axis }) => axis === point.axis)
                  .reduce(
                    (sum, p) => (point.chartType === p.chartType ? sum + (p.value ?? 0) : sum),
                    0,
                  ),
              );

              return (
                <circle
                  key={i}
                  cx={dateScale(point.date)}
                  cy={valueScales[point.axis](value)}
                  r={5}
                  fill={point.color}
                  stroke="white"
                  strokeOpacity={0.2}
                  strokeWidth={1}
                  pointerEvents="none"
                />
              );
            })}
      </>
    );
  };

  return (
    <div className={commonStyles.graph}>
      <svg width={width} height={isGrouped ? height + GroupAxisHeight : height}>
        {hasBars ? renderHighlightedPeriod() : null}
        {hideGrid ? null : (
          <>
            <GridRows
              width={width}
              height={height}
              scale={primaryValueScale}
              stroke={gridLineColor}
              tickValues={primaryValueScale.ticks(NumYTicks)}
            />
            <GridColumns
              width={width}
              height={height}
              scale={dateScale}
              stroke={gridLineColor}
              tickValues={getTickValues(aggPeriod ?? 'month', dateScale)}
              // eslint-disable-next-line react/no-children-prop
              children={(lines) => renderGridColumns(lines, { width, strokeColor: gridLineColor })}
            />
            <GridColumns
              width={width}
              height={height}
              scale={dateScale}
              stroke={gridHighlightedLineColor}
              tickValues={getHighlightedTickValues(aggPeriod ?? 'month', dateScale)}
              // eslint-disable-next-line react/no-children-prop
              children={(lines) =>
                renderGridColumns(lines, { width, strokeColor: gridHighlightedLineColor })
              }
            />
          </>
        )}

        {!isGrouped && // grouping is only supported for bar charts
          axes.map((axis) => {
            const areaKeys = data.series
              .filter((s) => s.chartType === 'area' && s.axis === axis)
              .map(({ key }) => key);
            const lineKeys = data.series
              .filter((s) => s.chartType === 'line' && s.axis === axis)
              .map(({ key }) => key);

            return domainSizes[axis] <= 0 ? null : (
              <React.Fragment key={axis}>
                <AreaStack
                  keys={areaKeys}
                  data={data}
                  valueScale={valueScales[axis]}
                  dateScale={dateScale}
                />

                <LineStack
                  keys={lineKeys}
                  data={data}
                  valueScale={valueScales[axis]}
                  dateScale={dateScale}
                />

                <StackValues
                  keys={areaKeys}
                  data={data}
                  valueScale={valueScales[axis]}
                  dateScale={dateScale}
                  reverse
                  avoidOverflow
                />

                <StackValues
                  keys={lineKeys}
                  data={data}
                  valueScale={valueScales[axis]}
                  dateScale={dateScale}
                  reverse
                  avoidOverflow
                />
              </React.Fragment>
            );
          })}

        {isGrouped ? renderGroupedBarStacks(data) : renderBarStacks(data)}

        {hideGrid ? null : (
          <>
            {domainSizes.left > 0 && (
              <AxisLeft
                left={yAxisWidths.left + 4}
                scale={valueScales.left}
                hideTicks
                hideAxisLine
                tickClassName={commonStyles.tickLabel}
                tickLabelProps={() => ({ dx: 4, dy: 10, textAnchor: 'end' })}
                tickValues={valueScales.left.ticks(NumYTicks)}
                tickFormat={(n) => formatLeftAxisValue(n.valueOf())}
              />
            )}
            {domainSizes.right > 0 && (
              <AxisRight
                left={width - 4}
                scale={valueScales.right}
                hideTicks
                hideAxisLine
                tickClassName={commonStyles.tickLabel}
                tickLabelProps={() => ({ dx: 0, dy: 10, x: 0, textAnchor: 'end' })}
                tickValues={rightAxisTicks}
                tickFormat={(n) => formatRightAxisValue(n.valueOf())}
              />
            )}
          </>
        )}
        {!isGrouped ? (
          <>
            {renderHighlightedDate()}
            <Bar
              width={width}
              height={height}
              fill="transparent"
              onTouchStart={onTouchMove}
              onTouchMove={onTouchMove}
              onMouseMove={(event) => onMouseMove?.(event.nativeEvent)}
              onMouseLeave={onMouseLeave}
              onClick={(e) => {
                if (!tooltipData || groupKey === undefined) {
                  return;
                }
                const { clientX, clientY } = e;
                const date = tooltipData.date.toISOString();
                onValueClick?.(clientX, clientY, groupKey, date, aggPeriod);
              }}
            />
          </>
        ) : null}
      </svg>

      {tooltipData !== null ? (
        isGroupedTooltipData(tooltipData) ? (
          <GroupedTooltip tooltipData={tooltipData} />
        ) : (
          <Tooltip tooltipData={tooltipData} />
        )
      ) : null}
    </div>
  );
};

type TooltipData = {
  date: Date;
  left: number;
  top: number;
  timezone: string;
  aggPeriod: TimeAggregationPeriod | null;
  points: {
    label: string;
    value: number | null;
    type: model.PropertyType | null;
    format?: common.PropertyValueFormat;
    date: Date;
    color: string;
    axis: 'left' | 'right';
    chartType: ChartType;
  }[];
};

type GroupedTooltipData = {
  date: Date;
  left: number;
  top: number;
  timezone: string;
  aggPeriod: TimeAggregationPeriod | null;
  groups: {
    label: string;
    points: {
      label: string;
      value: number | null;
      type: model.PropertyType | null;
      format?: common.PropertyValueFormat;
      date: Date;
      color: string;
      axis: 'left' | 'right';
      chartType: ChartType;
    }[];
  }[];
};

const isGroupedTooltipData = (data: TooltipData | GroupedTooltipData): data is GroupedTooltipData =>
  'groups' in data;

const Tooltip = (props: { tooltipData: TooltipData }) => {
  const { tooltipData } = props;

  if (tooltipData.points.length === 0) {
    return null;
  }

  const totalValueType = first(tooltipData.points)?.type ?? 'Number';
  const totalValueFormat = tooltipData.points.every(
    (point) => point.format === first(tooltipData.points)?.format,
  )
    ? first(tooltipData.points)?.format
    : undefined;

  return (
    <ChartTooltip
      top={tooltipData.top}
      left={tooltipData.left}
      offsetX={20}
      title={getFormattedDateForPeriod(
        tooltipData.date,
        tooltipData.aggPeriod,
        tooltipData.timezone,
      )}>
      <ul>
        {tooltipData.points.length > 1 && (
          <li>
            Total:{' '}
            <strong>
              {formatPropertyValue(
                tooltipData.points
                  .map((point) => point.value)
                  .filter(notNil)
                  .reduce((sum, value) => sum + value, 0),
                { type: totalValueType, format: totalValueFormat },
              )}
            </strong>
          </li>
        )}
        {tooltipData.points.map((point, i) => (
          <li key={i}>
            <span className={commonStyles.seriesMarker} style={{ background: point.color }} />
            {point.label ?? '-'}:{' '}
            <strong>
              {formatPropertyValue(point.value, { type: point.type, format: point.format })}
            </strong>
          </li>
        ))}
      </ul>
    </ChartTooltip>
  );
};

const GroupedTooltip = (props: { tooltipData: GroupedTooltipData }) => {
  const { tooltipData } = props;

  if (tooltipData.groups.length === 0) {
    return null;
  }

  const firstPoint = first(tooltipData.groups[0].points);
  const totalValueType = firstPoint?.type ?? 'Number';
  const totalValueFormat = firstPoint?.format;

  return (
    <ChartTooltip
      top={tooltipData.top}
      left={tooltipData.left}
      title={getFormattedDateForPeriod(
        tooltipData.date,
        tooltipData.aggPeriod,
        tooltipData.timezone,
      )}>
      <ul>
        {tooltipData.groups.length > 1 && (
          <li>
            Total:{' '}
            <strong>
              {formatPropertyValue(
                tooltipData.groups
                  .flatMap((group) => group.points.map((point) => point.value))
                  .filter(notNil)
                  .reduce((sum, value) => sum + value, 0),
                { type: totalValueType, format: totalValueFormat },
              )}
            </strong>
          </li>
        )}
        {tooltipData.groups.map((group, groupIdx) => (
          <li key={groupIdx} style={{ paddingTop: '5px' }}>
            <span>
              <strong>{group.label}</strong>
            </span>
            <ul>
              {group.points.map((point, j) => (
                <li key={j}>
                  <span className={commonStyles.seriesMarker} style={{ background: point.color }} />
                  {point.label ?? '-'}:{' '}
                  <strong>
                    {formatPropertyValue(point.value ?? '-', {
                      type: point.type,
                      format: point.format,
                    })}
                  </strong>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </ChartTooltip>
  );
};

import { isSameYear, parseISO } from 'date-fns';
import { isBoolean, isArray, isObject, memoize } from 'lodash';

import { common, model } from '@gosupersimple/types';

import {
  formatDate,
  getIntervalFormat,
  getWeekdayName,
  isInterval,
  justifyInterval,
} from '@/lib/date';

import { Field, TimeAggregationPeriod } from '../types';
import { isNumberType } from '.';

const ONE_MILLION = 1e6;

const isLessThanMillion = (
  value: number,
  format:
    | common.NumberPropertyValueFormat
    | common.FloatPropertyValueFormat
    | common.IntegerPropertyValueFormat
    | undefined,
) => Math.abs(value * (format === 'percentage' ? 100 : 1)) < ONE_MILLION;

export const limitLength = (value: string, maxLength: number | undefined) =>
  maxLength !== undefined && value.length > maxLength
    ? `${value.substring(0, maxLength - 3)}...`
    : value;

type NumberStyle = 'compact' | 'compactLong' | 'compactLongUnderMillion';

export interface PropertyFormatOptions {
  field?: Field;
  type?: model.PropertyType | null;
  locale?: string;
  maxLength?: number;
  timezone?: string;
  precision?: TimeAggregationPeriod;
  format?: common.PropertyValueFormat;
  style?: NumberStyle;
}

const getNumberFormat = memoize(
  (
    locale?: string,
    format?:
      | common.NumberPropertyValueFormat
      | common.FloatPropertyValueFormat
      | common.IntegerPropertyValueFormat,
    style?: NumberStyle,
  ) => {
    const compactOverrides =
      style === 'compact'
        ? {
            notation: 'compact' as const,
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }
        : {};
    const compactLongOverrides =
      style === 'compactLong'
        ? {
            notation: 'compact' as const,
            compactDisplay: 'long' as const,
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          }
        : {};
    const compactLongUnderMillionOverrides =
      style === 'compactLongUnderMillion'
        ? {
            notation: undefined,
            compactDisplay: undefined,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }
        : {};

    switch (format) {
      case 'percentage':
        return new Intl.NumberFormat(locale, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          ...compactOverrides,
          ...compactLongOverrides,
          ...compactLongUnderMillionOverrides,
        });
      case 'eur':
      case 'usd':
      case 'gbp':
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: format.toUpperCase(),
          maximumFractionDigits: 2,
          currencyDisplay: 'narrowSymbol',
          ...compactOverrides,
          ...compactLongOverrides,
          ...compactLongUnderMillionOverrides,
        });
      default:
        return new Intl.NumberFormat(undefined, {
          maximumFractionDigits: 6,
          ...compactOverrides,
          ...compactLongOverrides,
          ...compactLongUnderMillionOverrides,
        });
    }
  },
  (locale, format, style) => JSON.stringify({ locale, format, style }),
);

const getNumberFormatFn = (
  locale?: string,
  format?:
    | common.NumberPropertyValueFormat
    | common.FloatPropertyValueFormat
    | common.IntegerPropertyValueFormat,
  style?: NumberStyle,
) => {
  return (value: number) => {
    if (isLessThanMillion(value, format) && style === 'compactLong') {
      style = 'compactLongUnderMillion';
    }
    const numberFormat = getNumberFormat(locale, format, style);
    return numberFormat.format(value);
  };
};

const formatFloat = (
  value: number,
  format?: common.PropertyValueFormat,
  style?: NumberStyle,
  locale?: string,
) => {
  switch (format) {
    case 'percentage':
      return getNumberFormatFn(locale, format, style)(value);
    default:
      return formatNumber(value, format, style, locale);
  }
};

export const formatNumber = (
  value: number,
  format?: common.PropertyValueFormat,
  style?: NumberStyle,
  locale?: string,
) => {
  switch (format) {
    case 'eur':
    case 'usd':
    case 'gbp':
      return getNumberFormatFn(locale, format, style)(value);
    default:
      return getNumberFormatFn(locale, undefined, style)(value);
  }
};

const getYearFormat = memoize(
  (locale?: string) => new Intl.DateTimeFormat(locale, { year: 'numeric' }),
);
const getMonthFormat = memoize(
  (locale?: string) => new Intl.DateTimeFormat(locale, { month: 'numeric' }),
);
const getDayFormat = memoize(
  (locale?: string) => new Intl.DateTimeFormat(locale, { day: 'numeric' }),
);
const getHourFormat = memoize(
  (locale?: string) => new Intl.DateTimeFormat(locale, { hour: 'numeric' }),
);

const formatDatePart = (value: number, part: common.TimeInterval, locale?: string) => {
  switch (part) {
    case 'year':
      return getYearFormat(locale).format(new Date(value, 0));
    case 'quarter':
      return String(value);
    case 'month':
      return getMonthFormat(locale).format(new Date(2025, value - 1));
    case 'week':
      return String(value);
    case 'day':
      return value < 32 ? getDayFormat(locale).format(new Date(2025, 0, value)) : String(value);
    case 'day_of_week':
      return getWeekdayName(value);
    case 'hour':
      return getHourFormat(locale).format(new Date(2025, 0, 1, value));
    default:
      return '';
  }
};

export function formatPropertyValue(
  value: any,
  {
    field,
    type = null,
    locale,
    maxLength,
    timezone,
    precision,
    format,
    style,
  }: PropertyFormatOptions = {},
): string {
  type = type ?? field?.type ?? null;
  precision = precision ?? field?.precision;
  format =
    format ??
    field?.format ??
    (field?.pk === true || field?.relation !== undefined ? 'raw' : undefined);

  if (value === null || value === '') {
    // TODO: better handle nully Model properties
    return '-';
  }

  if (type === 'Integer' && format === 'date') {
    return precision !== undefined ? formatDatePart(value, precision, locale) : String(value);
  }

  if (type === 'Date' && typeof value === 'string' && format !== 'raw') {
    try {
      const d = parseISO(value);
      if (format === 'iso') {
        return value;
      }
      const shouldShowYear = !isSameYear(new Date(), d);
      let dateFormat = `MMMM do${shouldShowYear ? ' yyyy' : ''} HH:mm`;
      if (format === 'date' && precision === undefined) {
        dateFormat = `MMMM do${shouldShowYear ? ' yyyy' : ''}`;
      }
      if (format === 'date' || precision !== undefined) {
        dateFormat = getIntervalFormat(precision ?? 'day');
      }
      if (format === 'time') {
        dateFormat = 'HH:mm';
      }
      return formatDate(d, dateFormat, timezone);
    } catch {
      return limitLength(String(value), maxLength);
    }
  }
  if ((type === 'Boolean' || isBoolean(value)) && format !== 'raw') {
    if (value === true || value === 'true') {
      return 'Yes';
    }
    if (value === false || value === 'false') {
      return 'No';
    }
  }
  if ((type === 'Float' || type === 'Number') && !isNaN(value) && format !== 'raw') {
    return formatFloat(Number(value), format, style, locale);
  }
  if (isNumberType(type) && !isNaN(value) && format !== 'raw') {
    return formatNumber(Number(value), format, style, locale);
  }
  if (type === 'Object') {
    if (isObject(value)) {
      return limitLength(JSON.stringify(value, null, 2), maxLength);
    }
    return value;
  }
  if (typeof value === 'string') {
    return limitLength(value, maxLength);
  }
  if (type === 'Array' || (type === null && Array.isArray(value))) {
    if (isArray(value) && value.some((item) => isObject(item))) {
      return limitLength(JSON.stringify(value, null, 2), maxLength);
    }
    return limitLength(value.join(', '), maxLength);
  }
  if ((type === 'Interval' || type === 'String') && isInterval(value)) {
    value = justifyInterval(value);
    // Handle date intervals returned by Postgres
    if (value['days'] !== undefined) {
      return `${value['days']} days`;
    }
    if (value['hours'] !== undefined) {
      return `${value['hours']} hours`;
    }
    if (value['minutes'] !== undefined) {
      return `${value['minutes']} minutes`;
    }
    if (value['seconds'] !== undefined) {
      return `${value['seconds']} seconds`;
    }
    if (value['milliseconds'] !== undefined) {
      return `${value['milliseconds']} milliseconds`;
    }
    if (Object.keys(value).length === 0) {
      return '-';
    }
  }
  return limitLength(String(value), maxLength);
}

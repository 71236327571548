import { z } from 'zod';
import { Navigate, useParams } from 'react-router-dom';

import { useBuildAccountUrl, useSelectedAccount } from '../lib/accounts/context';
import { FullPageLoader } from '../components/loader';
import { useAlertConfigurationQuery } from '../graphql';
import { convertAlertConfigurationTypes } from '../explore/input';
import { buildExplorationUrl, getHashParameter, getModelExploration } from '../explore/utils';
import { isNil } from '../lib/utils';
import { PipelineOperation } from '../explore/types';
import { ErrorBanner } from '../components/banner';

const alertFiltersZod = z.array(
  z.object({
    key: z.string(),
    value: z.string(),
  }),
);

export const RedirectToAlertExploration = () => {
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();
  const { alertConfigurationId } = useParams();

  const filtersJson = getHashParameter(location.hash, 'filters');

  const { data: alertConfigurationData, loading } = useAlertConfigurationQuery({
    variables: { accountId: account.accountId, alertConfigurationId: alertConfigurationId ?? '' },
    skip: alertConfigurationId === undefined,
  });

  if (alertConfigurationId === undefined) {
    return <ErrorBanner title="Missing ID" description="The link may be invalid or incomplete" />;
  }

  if (loading) {
    return <FullPageLoader />;
  }

  const alertConfiguration = convertAlertConfigurationTypes(
    alertConfigurationData?.account?.alertConfiguration,
  );

  if (isNil(alertConfiguration)) {
    return <ErrorBanner title="Not Found" description="Alert configuration not found" />;
  }

  let filters;
  try {
    filters = alertFiltersZod.parse(JSON.parse(filtersJson ?? '[]'));
  } catch {
    return (
      <ErrorBanner title="Invalid parameters" description="The link may be invalid or incomplete" />
    );
  }

  // Apply filters to alert pipeline
  const pipeline = {
    ...alertConfiguration?.pipeline,
    operations: [
      ...alertConfiguration.pipeline.operations,
      ...filters.map(({ key, value }) => ({
        operation: 'filter',
        parameters: {
          key,
          operator: '==',
          value,
        },
      })),
    ],
  };

  const explorationUrl = buildAccountUrl(
    buildExplorationUrl(
      getModelExploration(
        {
          modelId: pipeline.baseModelId,
          name: alertConfiguration.name,
          labels: {},
        },
        // Big problem: alert can only contain dereferenced pipeline but exploration cannot
        pipeline.operations as PipelineOperation[],
      ),
      null,
    ),
  );

  return <Navigate to={explorationUrl} replace />;
};

import { isString } from 'lodash';

import { Cell, Exploration } from '@/explore/types';
import { generateUUID } from '@/lib/utils';
import { exhaustiveCheck } from '@/lib/exhaustive';

import { generateCellId, isCellWithTitle } from '../../cell';
import { createBasePipeline } from '../../pipeline';
import { starterCode as pythonStarterCode } from './python';

export const generateExplorationId = generateUUID;

export const buildEmptyExploration = (name: string): Exploration => ({
  explorationId: generateExplorationId(),
  name: name,
  labels: {},
  parameters: [],
  view: { cells: [] },
});

export const buildExploration = (name: string, cell: Cell): Exploration => ({
  explorationId: generateExplorationId(),
  name: name,
  labels: {},
  parameters: [],
  view: { cells: [cell] },
});

export const buildExplorationFromCells = (cells: Cell[]): Exploration => {
  const firstCell = cells.at(0);
  const name = firstCell !== undefined && isCellWithTitle(firstCell) ? firstCell.title : undefined;

  return {
    explorationId: generateExplorationId(),
    name: name ?? 'Untitled',
    labels: {},
    parameters: [],
    view: { canvas: undefined, cells },
  };
};

export type BuildType = 'funnel' | 'cohort' | 'variable' | 'text' | 'sql' | 'python';
export const buildExplorationByType = (
  type: string | BuildType,
  baseModelId: string,
): Exploration => {
  if (type === 'funnel') {
    // TODO: extract cell builders to cell core
    return buildExploration('Funnel', {
      id: generateCellId(),
      kind: 'funnel',
      title: 'My funnel',
      pipeline: createBasePipeline({
        baseModelId,
        operations: [
          {
            operation: 'funnel',
            parameters: {
              steps: [
                {
                  fields: [''],
                  sortKey: '',
                  pipeline: createBasePipeline({ baseModelId: '', operations: [] }),
                },
                {
                  fields: [''],
                  sortKey: '',
                  pipeline: createBasePipeline({ baseModelId: '', operations: [] }),
                },
              ],
              property: { name: 'Funnel', key: 'funnel' },
              groups: [],
            },
          },
        ],
      }),
    });
  }

  if (type === 'cohort') {
    return buildExploration('Cohort', {
      id: generateCellId(),
      kind: 'cohort',
      title: 'Retention chart',
      pipeline: createBasePipeline({ baseModelId, operations: [] }),
    });
  }

  if (type === 'variable') {
    return buildExploration('Variable', {
      id: generateCellId(),
      kind: 'variable',
      definition: { key: 'My variable 1', kind: 'string', defaultValue: '' },
    });
  }

  if (type === 'text') {
    return buildExploration('Text', { id: generateCellId(), kind: 'text', title: '', content: '' });
  }

  if (type === 'sql') {
    return buildExploration('SQL', {
      id: generateCellId(),
      kind: 'sql',
      title: 'SQL',
      pipeline: createBasePipeline({
        baseModelId,
        operations: [{ operation: 'sql', parameters: { sql: '' } }],
      }),
    });
  }

  if (type === 'python') {
    return buildExploration('Python', {
      id: generateCellId(),
      kind: 'python',
      title: 'Python code',
      pipeline: createBasePipeline({
        baseModelId,
        operations: [{ operation: 'python', parameters: { code: pythonStarterCode } }],
      }),
    });
  }

  if (isString(type)) {
    throw new Error(`Unknown build type: ${type}`);
  }

  exhaustiveCheck(type);
};

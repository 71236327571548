import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { useBuildAccountUrl } from '@/lib/accounts/context';
import { NavigableListItemOutput } from '@/lib/hooks/use-navigable-list';
import { useEventListener } from '@/lib/hooks/use-event-listener';

import { getExplorationType } from '@/core/exploration';

import { Icon } from '../../components/icon';
import { DefaultHiddenLabels, formatLabels, isModel } from './utils';
import { buildExplorationUrl } from '../utils';
import { ExplorationListItemData, ListItemData } from '.';
import { CellList } from './cell-list';

import styles from './exploration-search.module.scss';

interface ExplorationListProps {
  listItems: NavigableListItemOutput<ExplorationListItemData>[];
  hideSectionLabels?: boolean;
}

export const ExplorationList = (props: ExplorationListProps) => {
  const { listItems, hideSectionLabels } = props;
  return (
    <ol>
      {listItems.map((item, i) => (
        <li key={i}>
          <ListItem listItem={item} hideSectionLabel={hideSectionLabels} />
        </li>
      ))}
    </ol>
  );
};

interface ListItemProps {
  listItem: NavigableListItemOutput<ListItemData>;
  hideSectionLabel?: boolean;
}

const ListItem = (props: ListItemProps) => {
  const { listItem, hideSectionLabel = false } = props;
  const { getIsFocused, setIsFocused } = listItem;

  const buildAccountUrl = useBuildAccountUrl();

  const { exploration } = listItem.getData<ExplorationListItemData>();
  const { name, description, labels } = exploration;

  const type = getExplorationType(exploration);
  const url = buildAccountUrl(buildExplorationUrl(exploration));
  const ref = useRef<HTMLAnchorElement>(null);

  const isFocused = getIsFocused();
  useEventListener('focus', () => setIsFocused(true), ref);
  useEffect(() => {
    if (isFocused) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isFocused, ref]);

  const handleMouseOver = debounce(
    () => {
      setIsFocused(true);
    },
    100,
    { leading: true, trailing: false },
  );

  return (
    <>
      <Link
        to={url}
        className={classNames(styles.listItem, { [styles.focus]: isFocused })}
        onMouseOver={handleMouseOver}
        onMouseMove={handleMouseOver}
        onClick={listItem.onClick}
        ref={ref}>
        <Icon name={type === 'model' ? 'Model' : 'Exploration'} size={32} className={styles.icon} />
        <div className={styles.name}>{name}</div>
        {description !== undefined && (
          <div className={styles.description} title={description ?? undefined}>
            {description}
          </div>
        )}
        {isModel(labels) ? (
          <ul className={styles.labels}>
            {formatLabels(
              labels,
              hideSectionLabel ? [...DefaultHiddenLabels, 'section'] : DefaultHiddenLabels,
            ).map((label, idx) => (
              <li key={idx}>{label}</li>
            ))}
          </ul>
        ) : (
          <div className={styles.sectionCount}>Blocks: {exploration.view.cells.length}</div>
        )}
        {listItem.getChildren().length > 0 && (
          <div className={styles.expandIcon}>
            <Icon name={listItem.getIsCollapsed() ? 'ChevronDown' : 'ChevronUp'} size={24} />
          </div>
        )}
      </Link>
      {listItem.getChildren().length > 0 && (
        <div
          className={classNames(styles.cellList, {
            [styles.collapsed]: listItem.getIsCollapsed(),
          })}
          inert={listItem.getIsCollapsed() ? true : undefined}>
          <CellList exploration={exploration} listItems={listItem.getChildren()} />
        </div>
      )}
    </>
  );
};

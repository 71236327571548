import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { BubbleMenu, Editor, isNodeSelection } from '@tiptap/react';

import { useKeyPress } from '@/lib/hooks/use-key-press';
import { Icon } from '@/components/icon';
import { Tooltip } from '@/components/tooltip';
import { ShortcutIndicator } from '@/components/shortcut-indicator';

import styles from './text-cell.module.scss';

interface PopupMenuProps {
  editor: Editor | null;
}

export const PopupMenu = ({ editor }: PopupMenuProps) => {
  const [isLinkMenuOpen, setIsLinkMenuOpen] = useState(false);
  const [currentHref, setCurrentHref] = useState<string>();
  const [isLinkActive, setIsLinkActive] = useState(false);

  const handleHideLinkMenu = useCallback(() => {
    if (editor) {
      editor.chain().focus().setTextSelection(editor.view.state.selection.to).run();
    }
  }, [editor]);

  useEffect(() => {
    if (editor) {
      const updateLinkActive = () => {
        setIsLinkActive(editor.isActive('link'));
      };

      updateLinkActive();

      editor.on('selectionUpdate', updateLinkActive);
      editor.on('update', updateLinkActive);

      return () => {
        editor.off('selectionUpdate', updateLinkActive);
        editor.off('update', updateLinkActive);
      };
    }
  }, [editor]);

  const showLinkMenu = isLinkActive || isLinkMenuOpen;

  useKeyPress(
    ['k', 'meta'],
    (event) => {
      event.preventDefault();
      setIsLinkMenuOpen(true);
    },
    { capture: true, includeInputs: true },
  );

  if (editor === null) {
    return <></>;
  }

  // https://github.com/ueberdosis/tiptap/issues/4870#issuecomment-2041842633
  function isTextSelected(editor: Editor) {
    const nodesList = ['footnoteNode', 'referenceNode'];
    const {
      state: {
        selection,
        selection: { empty, from, to },
      },
    } = editor.view;

    if (
      empty ||
      !editor.isEditable ||
      from === to ||
      editor.isActive('referenceNode') ||
      editor.isActive('image') ||
      editor.isActive('uploadingImage') ||
      (isNodeSelection(selection) && nodesList.includes(selection.node.type.name))
    ) {
      return false;
    }

    return true;
  }

  const shouldShow = ({ editor }: { editor: Editor }) => {
    const activeTextSelection = isTextSelected(editor);

    if (activeTextSelection && editor.isActive('link')) {
      setCurrentHref(editor.getAttributes('link').href);
      setIsLinkMenuOpen(true);
      editor.chain().extendMarkRange('link').run();
      return true;
    }

    return activeTextSelection;
  };

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={shouldShow}
      tippyOptions={{
        onHide: () => {
          setIsLinkMenuOpen(false);
          setCurrentHref(undefined);
        },
      }}>
      {showLinkMenu ? (
        <LinkMenu editor={editor} initialHref={currentHref} hideLinkMenu={handleHideLinkMenu} />
      ) : (
        <TextMenu editor={editor} showLinkMenu={() => setIsLinkMenuOpen(true)} />
      )}
    </BubbleMenu>
  );
};

interface TextMenuProps {
  editor: Editor;
  showLinkMenu: () => void;
}

const TextMenu = ({ editor, showLinkMenu }: TextMenuProps) => (
  <div className={styles.popupMenu} onMouseDown={(event) => event.preventDefault()}>
    <Tooltip
      content={
        <>
          Bold <ShortcutIndicator shortcut={['cmd', 'B']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={classNames({ [styles.active]: editor.isActive('bold') })}>
        <Icon name="Bold" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Italic <ShortcutIndicator shortcut={['cmd', 'I']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={classNames({ [styles.active]: editor.isActive('italic') })}>
        <Icon name="Italic" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Underline <ShortcutIndicator shortcut={['cmd', 'U']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={classNames({ [styles.active]: editor.isActive('underline') })}>
        <Icon name="Underline" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Link <ShortcutIndicator shortcut={['cmd', 'K']} size="small" />
        </>
      }>
      <button onClick={() => showLinkMenu()}>
        <Icon name="Link" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Quote <ShortcutIndicator shortcut={['cmd', 'shift', 'B']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={classNames({ [styles.active]: editor.isActive('blockquote') })}>
        <Icon name="Quote" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Inline code snippet <ShortcutIndicator shortcut={['cmd', 'E']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={classNames({ [styles.active]: editor.isActive('code') })}>
        <Icon name="CodeInline" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Code block <ShortcutIndicator shortcut={['cmd', 'alt', 'C']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={classNames({ [styles.active]: editor.isActive('codeblock') })}>
        <Icon name="Code" size={16} />
      </button>
    </Tooltip>
  </div>
);

interface LinkMenuProps {
  editor: Editor;
  initialHref?: string;
  hideLinkMenu: () => void;
}

const LinkMenu = ({ editor, initialHref, hideLinkMenu }: LinkMenuProps) => {
  const [linkUrl, setLinkUrl] = useState<string>('');
  const urlInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialHref !== undefined) {
      setLinkUrl(initialHref);
    }
    urlInputRef.current?.focus();
  }, [initialHref]);

  useKeyPress(
    'escape',
    (event) => {
      event.stopPropagation();
      setLinkUrl('');
      hideLinkMenu();
    },
    { capture: true, includeInputs: true },
  );

  if (editor === null) {
    return <></>;
  }

  const handleUrlInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    saveLink();
  };

  const saveLink = () => {
    if (linkUrl === '') {
      return;
    }

    const href =
      linkUrl.startsWith('http://') || linkUrl.startsWith('https://')
        ? linkUrl
        : `https://${linkUrl}`;

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href })
      .setTextSelection(editor.view.state.selection.to)
      .run();

    setLinkUrl('');
    hideLinkMenu();
  };

  const removeLink = () => {
    editor.chain().focus().unsetLink().run();
    hideLinkMenu();
  };

  return (
    <div className={styles.popupMenu}>
      <input
        type="text"
        value={linkUrl}
        onChange={(event) => setLinkUrl(event.target.value)}
        placeholder="Enter link URL"
        onKeyDown={handleUrlInputKeyDown}
        className={styles.urlInput}
        ref={urlInputRef}
      />
      <Tooltip content="Open in new window">
        <button
          onClick={() =>
            window.open(editor.getAttributes('link').href, '_blank', 'noopener noreferrer')
          }>
          <Icon name="ExternalLink" size={16} />
        </button>
      </Tooltip>
      <Tooltip content="Remove link">
        <button onClick={removeLink}>
          <Icon name="Trash" size={16} />
        </button>
      </Tooltip>
    </div>
  );
};

import { BarRounded, BarStackHorizontal } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand } from '@visx/scale';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { ScaleLinear, ScaleOrdinal } from 'd3-scale';

import { common, model } from '@gosupersimple/types';

import { ValueClickHandler } from '@/explore/types';
import { formatPropertyValue } from '@/explore/utils/format';

import { ChartTooltipSingleValue } from '../common';
import { CategoryData } from '../grouped-chart/types';

import { getSeriesTotalsFormat } from '../grouped-chart/utils';

import chartStyles, { gridLineColor } from '../charts.module.scss';
import styles from './stacked-horizontal-bar-chart.module.scss';

type TooltipData = {
  categoryLabel: string;
  categoryValue: string;
  valueLabel: string;
  valueKey: string;
  value: number | null;
  color: string;
  type: model.PropertyType | null;
  format?: common.PropertyValueFormat;
};

interface HorizontalBarChartProps {
  categoryFormat: {
    precision?: string;
  };
  onValueClick?: ValueClickHandler;
  data: CategoryData;
  width: number;
  height: number;
  valueScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
}

const GroupWidth = parseInt(chartStyles.widthPerGroup);
const BarRadius = 5;
const CategoryPadding = 0.3;

export const StackedHorizontalBarChart = ({
  data,
  width,
  height,
  colorScale,
  valueScale,
  onValueClick,
}: HorizontalBarChartProps) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
    return true;
  };

  const categoryScale = scaleBand({
    domain: data.items.map((d) => d.categoryValue),
    padding: CategoryPadding,
    range: [0, height],
  });

  const keys = data.series.map(({ key }) => key);
  const stackedData = data.items.map((d) => ({
    categoryLabel: d.label,
    categoryValue: d.categoryValue,
    ...d.values,
    ...data.series.reduce(
      (acc, series) => ({
        ...acc,
        [series.key]: d.values[series.key],
      }),
      {},
    ),
    stackTotal: keys.reduce<number | null>(
      (acc, key) => (acc === null ? d.values[key] : acc + (d.values[key] ?? 0)),
      null,
    ),
  }));

  const renderBarSeries = () => (
    <BarStackHorizontal<Record<string, any>>
      data={stackedData}
      keys={keys}
      y={(d) => d.categoryValue}
      width={width}
      xScale={valueScale}
      yScale={categoryScale}
      color={(x) => colorScale(x.toString())}>
      {(barStacks) => {
        return barStacks.map((barStack, barStackIdx) =>
          barStack.bars
            .filter((bar) => bar.width > 0)
            .map((bar) => {
              const series = data.series.find((s) => s.key === bar.key);
              if (series === undefined) {
                throw new Error(`Series not found for bar chart key: ${bar.key}`);
              }
              return (
                <BarRounded
                  key={`${barStack.index}-${bar.index}`}
                  radius={BarRadius}
                  x={bar.x}
                  y={bar.y}
                  width={bar.width}
                  height={bar.height}
                  fill={bar.color}
                  right={barStackIdx === barStacks.length - 1}
                  onMouseMove={(e) => {
                    return handleMouseOver(e, {
                      value: Number(bar.bar.data[bar.key]),
                      valueKey: bar.key.toString(),
                      categoryLabel: bar.bar.data.categoryLabel,
                      categoryValue: bar.bar.data.categoryValue,
                      valueLabel: series?.label ?? bar.key.toString(),
                      color: bar.color,
                      type: series?.type ?? 'Number',
                      format: series?.format,
                    });
                  }}
                  onMouseOut={hideTooltip}
                  onClick={(e) => {
                    const item = data.items.at(bar.index);
                    if (item !== undefined) {
                      onValueClick?.(e.clientX, e.clientY, item.key, item.categoryValue);
                    }
                  }}
                />
              );
            }),
        );
      }}
    </BarStackHorizontal>
  );

  const renderSeriesValues = () => (
    <BarStackHorizontal<Record<string, any>>
      data={stackedData}
      keys={keys}
      y={(d) => d.categoryValue}
      width={width}
      xScale={valueScale}
      yScale={categoryScale}
      color={(x) => colorScale(x.toString())}>
      {(barStacks) => {
        return barStacks.map((barStack) => {
          return barStack.bars
            .filter((bar) => bar.width > 0)
            .map((bar) => {
              const series = data.series.find((s) => s.key === bar.key);
              const value = bar.bar.data[bar.key];
              if (series === undefined || !series.showValues || value === null) {
                return null;
              }
              return (
                <text
                  key={`${barStack.index}-${bar.index}`}
                  className={styles.valueLabel}
                  x={bar.x}
                  y={bar.y}
                  dx={7}
                  dy={1 + bar.height / 2}>
                  {formatPropertyValue(value, {
                    type: series.type,
                    format: series.format,
                    style: 'compact',
                  })}
                </text>
              );
            });
        });
      }}
    </BarStackHorizontal>
  );

  const renderTotalValues = () => {
    const showTotals = data.series.some(({ showValues }) => showValues);

    if (!showTotals) {
      return null;
    }

    const { type, format } = getSeriesTotalsFormat(data.series);

    return stackedData.map((item) => {
      if (item.stackTotal === null) {
        return null;
      }
      return (
        <text
          key={item.categoryValue}
          className={styles.valueLabel}
          x={valueScale(item.stackTotal)}
          y={(categoryScale(item.categoryValue) ?? 0) + categoryScale.bandwidth() / 2}
          dx={7}
          dy={1}>
          {formatPropertyValue(item.stackTotal, {
            type,
            format,
            style: 'compact',
          })}
        </text>
      );
    });
  };

  return (
    <div className={chartStyles.graph}>
      {tooltipData !== undefined ? (
        <ChartTooltipSingleValue
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={{
            label: tooltipData.categoryValue,
            value: tooltipData.value,
            type: tooltipData.type,
            format: tooltipData.format,
          }}
          tooltipOpen={tooltipOpen}
          categoryLabel={tooltipData.categoryLabel}
          valueLabel={tooltipData.valueLabel}
          seriesColor={tooltipData.color}
        />
      ) : null}
      <svg width={width} height={height}>
        <Group left={GroupWidth}>
          <GridColumns width={width} height={height} scale={valueScale} stroke={gridLineColor} />
          {renderBarSeries()}
          {renderSeriesValues()}
          {renderTotalValues()}

          <AxisLeft
            scale={categoryScale}
            stroke={gridLineColor}
            hideTicks
            tickLabelProps={{
              fill: '#FFF',
              fontSize: 12,
              textAnchor: 'end',
              width: 100,
              scaleToFit: 'shrink-only',
            }}
          />
        </Group>
      </svg>
    </div>
  );
};

import { common, model } from '@gosupersimple/types';
import { isArray } from 'lodash';

export type SingleSeriesMetaData = {
  key: string;
  type: model.PropertyType | null;
  format?: common.PropertyValueFormat;
  label: string;
  compactLabel: string;
  axis: 'left' | 'right';
  chartType: 'line' | 'bar' | 'area';
  color: string;
  showValues: boolean;
  minValue: number;
  maxValue: number;
};

export type PrimaryGroupSeriesMetaData = SingleSeriesMetaData & {
  primaryGroupLabel: string;
};

export type SecondaryGroupSeriesMetaData = PrimaryGroupSeriesMetaData & {
  secondaryGroupLabel: string;
};

export type SeriesMetaData =
  | SingleSeriesMetaData
  | PrimaryGroupSeriesMetaData
  | SecondaryGroupSeriesMetaData;

export const isPrimaryGroupSeriesMetaData = (
  data: SeriesMetaData,
): data is PrimaryGroupSeriesMetaData =>
  'primaryGroupLabel' in data && !('secondaryGroupLabel' in data);

export const isSecondaryGroupSeriesMetaData = (
  data: SeriesMetaData,
): data is SecondaryGroupSeriesMetaData =>
  'primaryGroupLabel' in data && 'secondaryGroupLabel' in data;

export type TimeSeriesData = {
  minValue: number;
  maxValue: number;
  series: SeriesMetaData[];
  items: {
    dateValue: Date;
    label: string;
    values: ItemValues;
  }[];
};

export type ItemValues = Record<string, number | null>;
export type GroupedItemValues = { group: string; values: ItemValues }[];
export const isGroupedValues = (values: ChartItemValues): values is GroupedItemValues =>
  'group' in values;

export type GroupedTimeSeriesData = {
  minValue: number;
  maxValue: number;
  series: SeriesMetaData[];
  items: {
    dateValue: Date;
    label: string;
    values: GroupedItemValues;
  }[];
};

export const isGroupedTimeSeriesData = (data: ChartData): data is GroupedTimeSeriesData =>
  'values' in data.items[0] && isArray(data.items[0].values);

export type CategoryData = {
  maxValue: number;
  minValue: number;
  series: SeriesMetaData[];
  items: {
    key: string;
    categoryValue: string;
    label: string;
    values: ItemValues;
  }[];
};

export type ChartData = TimeSeriesData | CategoryData | GroupedTimeSeriesData;
export type ChartItem = ChartData['items'][0];
export type ChartItemValues = ChartItem['values'];

export type GroupedChartData<T = ChartData> =
  | {
      type: 'group';
      label: string;
      items: GroupedChartData<T>;
    }[]
  | {
      type: 'data';
      chartData: T;
    };

import { z } from 'zod';
import { common, metric, model, pipeline } from '@gosupersimple/types';

import { timePrecision, timeRange } from '../lib/types';

export const explorationTypes = ['exploration', 'model', 'ai'] as const;
export type ExplorationType = (typeof explorationTypes)[number] | null;

export interface Exploration {
  explorationId: string;
  name: string;
  description?: string;
  labels: Record<string, string>;
  parameters: { key: string; modelId: string }[];
  view: {
    cells: Cell[];
    canvas?: CanvasState;
  };
  options?: {
    /**
     * Stores the persisted exploration ID if available. Used to link back to the persisted state.
     */
    explorationSourceId?: string;
    previousStateId?: string;
    explorationForModelId?: string;
    detailExplorationForModelId?: string;
  };
}

export interface CanvasEdge {
  id: string;
  source: string;
  target: string;
}

export interface CanvasNode {
  id: string;
  position: { x: number; y: number };
  measured: { width: number; height: number };
  zIndex: number;
}

export interface CanvasState {
  edges: CanvasEdge[];
  nodes: CanvasNode[];
}

export interface ChatExploration {
  id: string;
  role: 'assistant';
  type: 'exploration';
  exploration: Exploration;
}

export interface ChatUserMessage {
  id: string;
  message: string;
  role: 'user';
  type: 'initial_user_prompt' | 'clarifying_answer' | 'followup_question';
}

export interface ChatAssistantMessage {
  id: string;
  message: string;
  role: 'assistant';
  type: 'clarifying_question';
}

export type ChatMessage = ChatUserMessage | ChatAssistantMessage;

export type SortItem = common.Sorting;

export type Sort = SortItem[];

export const cellViewOptions = z
  .object({
    tableVisibility: z.enum(['hidden', 'visible']),
    pipelinePreviewVisibility: z.enum(['hidden', 'visible']),
    viewMode: z.enum(['cards', 'table']),
    collapsed: z.string(),
    rowId: z.string(),
    height: z.number(),
  })
  .partial();

export type CellViewOptions = z.infer<typeof cellViewOptions>;

export type RecordsCell = {
  id: string;
  kind: 'records';
  title?: string | null;
  excludeProperties?: string[] | null;
  pipeline: Pipeline;
  visualisations?: Visualisation[];
  sort?: Sort;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type FunnelCell = {
  id: string;
  kind: 'funnel';
  title?: string | null;
  pipeline: Pipeline;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type CohortCell = {
  id: string;
  kind: 'cohort';
  title?: string | null;
  pipeline: Pipeline;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type SqlCell = {
  id: string;
  kind: 'sql';
  title?: string | null;
  pipeline: Pipeline;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type PythonCell = {
  id: string;
  kind: 'python';
  title?: string | null;
  pipeline: Pipeline;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type InvalidCell = {
  id: string;
  title?: string | null;
  kind: 'invalid';
  message: string;
  viewOptions?: CellViewOptions;
  cell: Exclude<Cell, InvalidCell>;
};

export type VariableCell = {
  id: string;
  kind: 'variable';
  definition: VariableDefinition;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type TextCell = {
  id: string;
  kind: 'text';
  title?: string | null;
  content?: string | null;
  viewOptions?: CellViewOptions;
  conversationId?: string;
};

export type ChatCell = {
  id: string;
  kind: 'chat';
  conversationId: string;
  messages: ChatMessage[];
  viewOptions?: CellViewOptions;
};

export type ConversationCell = Cell & {
  conversationId: string;
};

export type Cell =
  | RecordsCell
  | FunnelCell
  | VariableCell
  | CohortCell
  | SqlCell
  | PythonCell
  | TextCell
  | InvalidCell
  | ChatCell;

export type RecordsLikeCell = RecordsCell | SqlCell;

export type CopiableCell = RecordsCell | FunnelCell | CohortCell | SqlCell | PythonCell | TextCell;
export type CellWithPipeline = Extract<Cell, { pipeline: Pipeline }>;

export const timeRangeParameter = timeRange;

export const dateRangeParameter = z.object({
  range: timeRangeParameter,
  start: z.string(),
  end: z.string(),
  precision: timePrecision,
});

export type DateRangeParameter = z.infer<typeof dateRangeParameter>;

export const stringParameter = z.string();

export const numberParameter = z.number();

export const booleanParameter = z.boolean();

export const enumParameter = z.string();

export const dateParameter = z.string();

export const timeIntervalParameter = common.timeInterval;

export type DateParameter = z.infer<typeof dateParameter>;

export const explorationParameter = stringParameter
  .or(numberParameter)
  .or(enumParameter)
  .or(booleanParameter)
  .or(dateRangeParameter);

const _explorationParameters = z.record(z.string(), explorationParameter);

export type ExplorationParameter = z.infer<typeof explorationParameter>;

export type ExplorationParameters = z.infer<typeof _explorationParameters>;

const stringVariableDefinition = z.object({
  kind: z.literal('string'),
  key: z.string(),
  defaultValue: stringParameter,
});

const numberVariableDefinition = z.object({
  kind: z.literal('number'),
  key: z.string(),
  defaultValue: numberParameter,
});

export type DateRangeVariableDefinition = z.infer<typeof dateRangeVariableDefinition>;

const enumVariableDefinition = z.object({
  kind: z.literal('enum'),
  key: z.string(),
  source: z.literal('static').or(z.literal('query')).optional().default('static'),
  options: z.array(z.object({ value: z.string() })),
  queryOptions: z
    .object({ pipelineId: z.string(), field: z.string() })
    .optional()
    .default({ pipelineId: '', field: '' }),
  defaultValue: enumParameter,
});
export type EnumVariableDefinition = z.infer<typeof enumVariableDefinition>;

const booleanVariableDefinition = z.object({
  kind: z.literal('boolean'),
  key: z.string(),
  defaultValue: booleanParameter,
});

const dateVariableDefinition = z.object({
  kind: z.literal('date'),
  key: z.string(),
  defaultValue: dateParameter,
});

const dynamicVariableDefinition = z
  .object({
    kind: z.literal('dynamic'),
    key: z.string(),
    pipelineId: z.string(),
    field: z.string(),
    defaultValue: stringParameter,
  })
  .transform(
    (input): EnumVariableDefinition => ({
      kind: 'enum',
      key: input.key,
      source: 'query',
      options: [],
      queryOptions: { pipelineId: input.pipelineId, field: input.field },
      defaultValue: input.defaultValue,
    }),
  );
export type DynamicVariableDefinition = z.infer<typeof dynamicVariableDefinition>;

const dateRangeVariableDefinition = z.object({
  kind: z.literal('date_range'),
  key: z.string(),
  defaultRange: timeRangeParameter,
});

const timeIntervalVariableDefinition = z.object({
  kind: z.literal('time_interval'),
  key: z.string(),
  defaultValue: timeIntervalParameter,
});

export const variableDefinition = z.union([
  stringVariableDefinition,
  numberVariableDefinition,
  enumVariableDefinition,
  booleanVariableDefinition,
  dateVariableDefinition,
  dynamicVariableDefinition,
  dateRangeVariableDefinition,
  timeIntervalVariableDefinition,
]);

export type VariableDefinition = z.infer<typeof variableDefinition>;

export type BasePipeline = {
  pipelineId: string;
  baseModelId: string;
  operations: PipelineOperation[];
};

export type PipelineWithParent = {
  pipelineId: string;
  parentId: string;
  operations: PipelineOperation[];
};

export type DereferencedPipeline = {
  pipelineId: string;
  baseModelId: string;
  operations: DereferencedPipelineOperation[];
};

export type Pipeline = BasePipeline | PipelineWithParent;

export type PipelineOperation = (
  | DeriveFieldOperation
  | FilterOperation
  | GroupAggregateOperation
  | AddRelatedColumnOperation
  | RelationAggregateOperation
  | SwitchToRelationOperation
  | JoinPipelineOperation
  | HistogramOperation
  | FunnelOperation
  | CohortOperation
  | SqlOperation
  | PythonOperation
  | InvalidOperation
) & {
  disabled?: boolean;
};

export type DereferencedPipelineOperation = (
  | DeriveFieldOperation
  | FilterOperation
  | GroupAggregateOperation
  | DereferencedAddRelatedColumnOperation
  | DereferencedRelationAggregateOperation
  | SwitchToRelationOperation
  | DereferencedJoinPipelineOperation
  | HistogramOperation
  | DereferencedFunnelOperation
  | DereferencedCohortOperation
  | SqlOperation
  | PythonOperation
  | InvalidOperation
) & {
  disabled?: boolean;
};

export type Property = z.infer<typeof common.property>;

export const timeAggregationPeriodZod = common.timeInterval;
export type TimeAggregationPeriod = z.infer<typeof timeAggregationPeriodZod>;

export const groupingZod = common.grouping;
export type Grouping = common.Grouping;

const _aggregationTypeZod = common.aggregationType;
export type AggregationType = z.infer<typeof _aggregationTypeZod>;

export const aggregationZod = common.aggregation;
export type Aggregation = common.Aggregation;

export type KeyedAggregation = Extract<Aggregation, { key: string }>;
export type ExpressionAggregation = Extract<Aggregation, { value: common.ExpressionValue }>;
export type MetricAggregation = Extract<Aggregation, { metricId: string }>;
export type SortedAggregation = Extract<Aggregation, { sort: common.Sorting[] }>;

export type AggregationExtendedType = AggregationType | 'earliest' | 'latest';

export type FilterOperator =
  | '=='
  | '!='
  | '>'
  | '>='
  | '<'
  | '<='
  | 'in'
  | 'notin'
  | 'icontains'
  | 'noticontains'
  | 'arrcontains'
  | 'isnull'
  | 'isnotnull'
  | 'isempty'
  | 'isnotempty';

export type FilterValue = common.FilterValue;
export type FilterCondition = common.FilterCondition;
export type CompositeFilterCondition = common.CompositeFilterCondition;
export type FunnelStep = pipeline.FunnelStep;

export type DeriveFieldOperation = pipeline.DeriveFieldOperation;
export type FilterOperation = pipeline.FilterOperation;
export type GroupAggregateOperation = pipeline.GroupAggregateOperation;
export type AddRelatedColumnOperation = pipeline.AddRelatedColumnOperation;
export type RelationAggregateOperation = pipeline.RelationAggregateOperation;
export type SwitchToRelationOperation = pipeline.SwitchToRelationOperation;
export type JoinPipelineOperation = pipeline.JoinPipelineOperation;
type HistogramOperation = pipeline.HistogramOperation;
export type FunnelOperation = pipeline.FunnelOperation;
export type CohortOperation = pipeline.CohortOperation;
export type SqlOperation = pipeline.SQLOperation;
export type PythonOperation = pipeline.PythonOperation;
export type InvalidOperation = pipeline.InvalidOperation;

interface DereferencedAddRelatedColumnOperation {
  operation: AddRelatedColumnOperation['operation'];
  parameters: Omit<AddRelatedColumnOperation['parameters'], 'pipelineId'> & {
    pipeline?: DereferencedPipeline;
  };
}

type DereferencedJoinPipelineOperation = Omit<JoinPipelineOperation, 'parameters'> & {
  parameters: Omit<JoinPipelineOperation['parameters'], 'pipeline'> & {
    pipeline: DereferencedPipeline;
  };
};

interface DereferencedRelationAggregateOperation {
  operation: RelationAggregateOperation['operation'];
  parameters:
    | (Omit<RelationAggregateOperation['parameters'], 'pipelineId'> & {
        relation: { modelId?: string; key: string };
        pipeline?: DereferencedPipeline;
      })
    | (Omit<RelationAggregateOperation['parameters'], 'pipelineId'> & {
        joinStrategy: {
          joinKeyOnBase: string;
          joinKeyOnRelated: string;
        };
        pipeline: DereferencedPipeline;
      });
}

type DereferencedFunnelStep = Omit<FunnelStep, 'pipeline'> & {
  modelId: string;
  operations: DereferencedPipelineOperation[];
};

export type DereferencedFunnelOperation = Omit<FunnelOperation, 'parameters'> & {
  parameters: Omit<FunnelOperation['parameters'], 'steps'> & {
    steps: DereferencedFunnelStep[];
  };
};

type DereferencedCohortOperation = {
  operation: 'cohort';
  parameters: Omit<CohortOperation['parameters'], 'pipeline'> & {
    pipeline: DereferencedPipeline;
  };
};

export const bigNumberOptions = z.object({
  key: z.string(),
  sort: common.sorting.array().optional(),
});
export const bigNumberWithComparisonOptions = bigNumberOptions.extend({
  comparisonKey: z.string(),
  comparisonType: z.enum(['absolute', 'percentage']).optional(),
  comparisonDirection: z.enum(['higher', 'lower']).optional(),
  showComparisonLabel: z.boolean().optional(),
});

export type BigNumberOptions = z.infer<typeof bigNumberOptions>;
export type BigNumberWithComparisonOptions = z.infer<typeof bigNumberWithComparisonOptions>;

const chartTypeSchema = z.enum(['area', 'line', 'bar']);
export type ChartType = z.infer<typeof chartTypeSchema>;

const seriesSchema = z.record(
  z.object({
    chartType: chartTypeSchema.optional(),
    showValues: z.boolean().optional(),
    color: z.string().optional(),
  }),
);
export type SeriesViewOptions = z.infer<typeof seriesSchema>;

export const visualisationViewOptionsZod = z
  .object({
    stacked: z.boolean().default(false),
    bigNumberFieldKey: z.string().optional(),
    bigNumber: bigNumberWithComparisonOptions.or(bigNumberOptions),
    axes: z
      .object({
        right: z.object({
          keys: z.string().array(),
        }),
      })
      .default({
        right: {
          keys: [],
        },
      }),
    series: seriesSchema.optional(),
  })
  .partial();

export type ValueClickHandler = (
  x: number,
  y: number,
  key: string,
  value: string | NumberRange,
  precision?: TimeAggregationPeriod | null,
) => void;

export type VisualisationViewOptions = z.infer<typeof visualisationViewOptionsZod>;

export const aggregatedVisualisationZod = z.object({
  aggregation: z.object({
    groups: z.array(groupingZod),
    aggregations: z.array(aggregationZod),
  }),
  mainAxisKey: z.string().optional(),
  valueKeys: z.array(z.string()),
  viewOptions: visualisationViewOptionsZod.optional(),
});
export type AggregatedVisualisation = z.infer<typeof aggregatedVisualisationZod>;
export const isAggregatedVisualisation = (
  visualisation: Visualisation,
): visualisation is AggregatedVisualisation => 'aggregation' in visualisation;

export const simpleVisualisationZod = z.object({
  mainAxisKey: z.string().optional(),
  valueKeys: z.array(z.string()),
  viewOptions: visualisationViewOptionsZod.optional(),
});
export type SimpleVisualisation = z.infer<typeof simpleVisualisationZod>;
export const isSimpleVisualisation = (
  visualisation: Visualisation,
): visualisation is SimpleVisualisation =>
  !('aggregation' in visualisation) && visualisation.mainAxisKey !== undefined;

export const visualisationZod = aggregatedVisualisationZod.or(simpleVisualisationZod);
export type Visualisation = z.infer<typeof visualisationZod>;

export const modelKinds = ['Account', 'User', 'Event', 'Entity'] as const;
export type ModelKind = (typeof modelKinds)[number];

export type Model = {
  modelId: string;
  name: string;
  description?: string;
  primaryKey: string[];
  properties: {
    key: string;
    name: string;
    type: model.PropertyType;
    format?: common.PropertyValueFormat;
    description?: string;
  }[];
  relations: Relation[];
  labels: Record<string, string>;
  semantics?:
    | {
        kind: ModelKind;
        properties: {
          createdAt: string;
        };
      }
    | Record<string, never>;
};

export type JoinStrategyDirect = {
  joinKeyOnBase: string;
  joinKeyOnRelated: string;
  through?: {
    modelId: string;
    joinKeyToBase?: string;
    joinKeyToRelated?: string;
  };
};

export type JoinStrategySteps = {
  steps: { relationKey: string }[];
};

export type Relation = {
  key: string;
  modelId: string;
  type: string;
  name: string;
  joinStrategy?: JoinStrategyDirect | JoinStrategySteps;
};

export interface Point {
  value: number;
  date: string;
}

export type Metric = metric.Metric;

interface EnumOption {
  label: string;
  value: string;
}

export type EnumOptions = EnumOption[];

export interface Field {
  key: string;
  name: string;
  type: model.PropertyType | null;
  pk?: boolean;
  relation?: { key: string; modelId: string; name: string };
  model?: { modelId: string; name: string; propertyKey: string };
  precision?: TimeAggregationPeriod;
  format?: common.PropertyValueFormat;
  description?: string;
}

export type Fields = Field[];

export type QueryVariables = common.QueryVariables;

export type PipelineStateRelation = Relation & { baseModelId: string };

export interface PipelineState {
  /**
   * @deprecated
   */
  model: Model;
  relations: PipelineStateRelation[];
  fields: Fields;
}

const numberRange = z.object({
  start: z.number(),
  end: z.number(),
});

export type NumberRange = z.infer<typeof numberRange>;
export const isNumberRange = (value: unknown): value is NumberRange => {
  return numberRange.safeParse(value).success;
};

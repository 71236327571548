import { uniq } from 'lodash';

import { Cell, Exploration, ExplorationType, explorationTypes } from '@/explore/types';
import { notNil } from '@/lib/utils';

import {
  insertCellsAfter,
  insertCellsAtIdx,
  isCellWithPipeline,
  isConversationCell,
  setCellPipeline,
  normalizeConversationBlocks,
} from '../cell';
import { removeReferenceToParentPipeline } from '../pipeline';

export * from './builder';
export * from './conversation';

export const isModelExploration = (exploration: Exploration) =>
  getModelIdFromModelExploration(exploration) !== undefined ||
  getExplorationType(exploration) === 'model';

const getModelIdFromModelExploration = (exploration: Exploration) =>
  exploration.options?.explorationForModelId;

const getExplorationSection = (exploration: Exploration) => exploration.labels.section;

export const hasExplorationSection = (exploration: Exploration) =>
  notNil(getExplorationSection(exploration));

export const getExplorationSections = (explorations: Exploration[]) =>
  uniq(explorations.map(getExplorationSection).filter(notNil)).sort();

export const getCell = (exploration: Exploration, cellId: string) =>
  exploration.view.cells.find((cell) => cell.id === cellId);

export const addCells = (exploration: Exploration, cells: Cell[], afterCellId: string) => {
  return replaceCells(exploration, insertCellsAfter(exploration.view.cells, cells, afterCellId));
};

export const addCellsAt = (exploration: Exploration, cells: Cell[], index = 0) =>
  replaceCells(exploration, insertCellsAtIdx(exploration.view.cells, cells, index));

export const replaceCells = (exploration: Exploration, cells: Cell[]) => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells,
  },
});

export const deleteExplorationCell = (exploration: Exploration, cellId: string): Exploration => {
  const deletedCell = getCell(exploration, cellId);

  if (deletedCell === undefined) {
    throw new Error(`Could not find cell with id ${cellId}`);
  }

  const cells = exploration.view.cells;
  // TODO: move to @core/cell deleteCell(cells: Cell[], cellId: string): Cell[]
  const updatedCells = cells
    .filter((cell) => cell.id !== cellId)
    .map((cell) => {
      if (!isCellWithPipeline(cell) || !isCellWithPipeline(deletedCell)) {
        return cell;
      }

      return setCellPipeline(
        cell,
        removeReferenceToParentPipeline(cell.pipeline, deletedCell.pipeline),
      );
    });

  return replaceCells(exploration, normalizeConversationBlocks(updatedCells));
};

export const isEmptyExploration = (exploration: Exploration) =>
  exploration === undefined || exploration.view.cells.length === 0;

export const removeExplorationConversation = (
  exploration: Exploration,
  conversationId: string,
) => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells: exploration.view.cells.filter(
      (c) => !isConversationCell(c) || c.conversationId !== conversationId,
    ),
  },
});

export const getExplorationType = (exploration: Exploration): ExplorationType =>
  explorationTypes.find((type) => exploration.labels.type === type) ?? null;

import { isCellWithVisualisations, isRecordsCell, setCellVisualisations } from '@/core/cell';

import { getCell } from '../exploration/utils';
import { convertVisualisation } from '../input';
import { flattenPipeline, getNumberOfChildPipelines } from '../pipeline/utils';
import { Cell, Exploration } from '../types';

export const migrateVisualisations = (exploration: Exploration) => {
  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: exploration.view.cells.map((cell) => {
        if (
          isRecordsCell(cell) &&
          isCellWithVisualisations(cell) &&
          cell.visualisations !== undefined
        ) {
          return setCellVisualisations(cell, cell.visualisations.map(convertVisualisation));
        }

        return cell;
      }),
    },
  };
};

export const getCellPipeline = (cell: Cell | undefined) => {
  return cell === undefined
    ? undefined
    : 'pipeline' in cell
      ? cell.pipeline
      : cell.kind === 'invalid' && 'pipeline' in cell.cell
        ? cell.cell.pipeline
        : undefined;
};

export const getCellBaseModel = (cell: Cell | undefined, exploration: Exploration) => {
  const pipeline = getCellPipeline(cell);
  return pipeline !== undefined ? flattenPipeline(pipeline, exploration).baseModelId : undefined;
};

export const isMasterCell = (cellId: string, exploration: Exploration) => {
  const pipeline = getCellPipeline(getCell(cellId, exploration));
  if (pipeline === undefined) {
    return false;
  }
  return getNumberOfChildPipelines(exploration, pipeline) > 0;
};

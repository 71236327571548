import { Fragment, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { sample, findLastIndex } from 'lodash';
import classNames from 'classnames';

import { useTrackEvent } from '@/lib/analytics';
import { IconButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { Tooltip } from '@/components/tooltip';
import { useExplorationChatLazyQuery } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';
import { generateUUID } from '@/lib/utils';
import { isTimeoutError } from '@/lib/error/utils/timeout';
import { convertChatMessage } from '@/explore/input';
import { exportExploration, exportChatMessage } from '@/explore/output';
import { removeExplorationConversation, replaceCells } from '@/core/exploration';
import { removeConversationEditFlow } from '@/core/exploration/conversation';
import {
  buildEmptyChatCell,
  belongsToConversation,
  convertConversationCellsToMessages,
  discardConversationAfterMessage,
  getConversationCells,
  isChatCell,
  isConversationCell,
  unlinkConversationCell,
  updateConversationMessage,
  isConversationStart,
  isConversationEnd,
  getLastUserMessageIndex,
} from '@/core/cell';
import {
  Exploration,
  Cell,
  ChatCell,
  ChatMessage,
  ChatExploration,
  ChatAssistantMessage,
  ChatUserMessage,
} from '@/explore/types';

import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import { UserMessageInput } from './user-message-input';
import { InitialMessage } from './initial-message';
import { UserMessage } from './user-message';

import styles from './chat-cell.module.scss';

const isExplorationMessage = (msg: ChatMessage | ChatExploration): msg is ChatExploration =>
  msg.type === 'exploration';

const loadingMessages = [
  'Analyzing your question',
  'Searching through data',
  'Thinking of a great solution',
  'Retrieving relevant data',
  'Analyzing the information',
  'Ruminating on the question',
  'Conjuring up a response',
  'Tapping into the collective unconscious',
  'Channeling the wisdom of the ages',
  'Plotting the trajectory of insight',
  'Thinking really hard',
  'Accessing mainframe...',
  'Reticulating splines...',
];
// const longerLoadingMessages = [
//   'Finishing up',
//   'Coming up with something better',
//   'Unraveling the mysteries of the universe',
//   'Transcending the boundaries of knowledge',
//   'Embracing the ambiguity of existence',
// ];
// TODO: Display a second loading message from longerLoadingMessages once N time has passed
// const intervalSpeed = 4000;

interface ChatInputProps {
  messages: ChatMessage[];
  status?: 'loading' | 'error';
  statusMessage?: string;
  pendingFirstInput?: boolean;
  editableInitialMessage?: boolean;
  draft?: string;
  onSubmit(answer: string): void;
  onAccept: () => void;
  onDiscard: () => void;
  onCancel: () => void;
  onMessageChange: (message: ChatUserMessage) => void;
  onRetry: () => void;
}

const ChatInput = ({
  messages,
  status,
  statusMessage,
  pendingFirstInput = false,
  editableInitialMessage = false,
  draft,
  onSubmit,
  onAccept,
  onDiscard,
  onCancel,
  onMessageChange,
  onRetry,
}: ChatInputProps) => {
  const [lastMessage] = messages.slice(-1);

  const handleAnswer = (answer: string) => {
    if (!answer.length) {
      return onAccept();
    }

    onSubmit(answer);
  };

  if (!messages.length) {
    return (
      <div className={styles.form}>
        <UserMessageInput
          placeholder="Follow up or request edits"
          actions={(value) => [
            { label: value.length > 0 ? 'Ask' : 'Accept', onClick: () => handleAnswer(value) },
            {
              label: pendingFirstInput ? 'Cancel' : 'Discard last',
              variant: 'secondary',
              onClick: onDiscard,
            },
          ]}
          autoFocus
        />
      </div>
    );
  }

  const steps = messages.slice(1);

  const hasAnswer = (idx: number) =>
    steps.some((step, i) => i > idx && step.type === 'clarifying_answer');

  const [firstMessage] = messages;

  if (firstMessage.role !== 'user') {
    throw new Error('First message must be from the user');
  }

  return (
    <div className={styles.form}>
      <InitialMessage
        editable={editableInitialMessage}
        initialValue={draft}
        message={firstMessage}
        onChange={onMessageChange}
        onDiscard={onDiscard}
      />
      <div className={styles.steps}>
        {steps.length > 0 && (
          <>
            {steps.map((step, index) => {
              switch (step.type) {
                case 'clarifying_question':
                  return (
                    <Fragment key={index}>
                      {hasAnswer(index) ? null : (
                        <div className={classNames([styles.step, styles.clarifying])}>
                          <Icon name="PauseIcon" size={16} className={styles.pauseIcon} />
                          Clarifying user intent
                        </div>
                      )}
                      <div className={styles.questionContainer}>
                        <div className={styles.avatar}>
                          <Icon name="Zap" size={24} />
                        </div>
                        <div className={styles.content}>
                          <div className={styles.clarificationHeader}>Clarifying question</div>
                          <div className={styles.question}>{step.message}</div>
                        </div>
                      </div>
                    </Fragment>
                  );
                case 'clarifying_answer':
                  return <UserMessage key={step.id} message={step} onChange={onMessageChange} />;
                case 'followup_question':
                case 'initial_user_prompt':
                  throw new Error('Unhandled follow-up chat flow');
              }
            })}
          </>
        )}

        {status !== undefined && (
          <div className={styles.step}>
            {status === 'error' ? (
              <>
                <Icon name="X" size={16} />
                {statusMessage}
                <Tooltip content="Try again">
                  <IconButton icon="RotateCcw" className={styles.retryBtn} onClick={onRetry} />
                </Tooltip>
              </>
            ) : (
              <>
                <Loader size="small" type="spinner-dark" />
                {statusMessage}
              </>
            )}
          </div>
        )}

        {lastMessage.type === 'clarifying_question' && (
          <UserMessageInput
            initialValue={draft}
            placeholder="Reply to the clarifying question"
            autoFocus
            actions={(value) =>
              value.length > 0
                ? [
                    { label: 'Answer', onClick: () => handleAnswer(value) },
                    { label: 'Cancel', variant: 'secondary', onClick: () => onCancel() },
                  ]
                : [{ label: 'Cancel', variant: 'secondary', onClick: () => onCancel() }]
            }
          />
        )}
      </div>
    </div>
  );
};

interface ChatCellViewProps {
  cell: ChatCell;
  exploration: Exploration;
}

export const ChatCellView = (props: ChatCellViewProps) => {
  const { cell, exploration: currentExploration } = props;
  const account = useSelectedAccount();
  const {
    setExploration,
    scrollToCell,
    setNewCellIndex,
    selectCell,
    selectedCell,
    conversationDrafts,
    setConversationDraft,
  } = useExplorationContext();
  const { cellIndex, setCell } = useExplorationCellContext();
  const trackEvent = useTrackEvent();
  const [messages, setMessages] = useState(cell.messages ?? []);
  const [status, setStatus] = useState<'loading' | 'error' | undefined>();
  const [statusMessage, setStatusMessage] = useState<string>();
  const initialLoadCheck = useRef(false);
  const abortSignalRef = useRef(new AbortController());

  const [queryNextMessage, queryState] = useExplorationChatLazyQuery({
    onCompleted(data) {
      const [msg] = data?.account?.ask.messages ?? [];
      if (msg === undefined) {
        return;
      }

      const message = convertChatMessage(msg);
      setStatus(undefined);
      setStatusMessage(undefined);

      // TTQ responded with 1 or more cells
      if (isExplorationMessage(message)) {
        return handleGeneratedCells(message.exploration.view.cells);
      }

      setMessages(messages.concat([message]));
      setCell({
        ...cell,
        messages: messages.concat([message]),
      });
    },
    onError(error) {
      if (isTimeoutError(error)) {
        const timeoutMessage: ChatAssistantMessage = {
          id: generateUUID(),
          role: 'assistant',
          type: 'clarifying_question',
          message: `Unfortunately, I couldn't put together a great answer in time. I could just try again, or you could help me out by rephrasing the question or breaking it down into smaller parts. How would you like me to continue?`,
        };

        setMessages(messages.concat([timeoutMessage]));
        setStatus(undefined);
        setStatusMessage(undefined);
        return;
      }

      setStatus('error');
    },
  });

  const createMessage = (question: string) => {
    return {
      id: generateUUID(),
      type: messages.length ? ('clarifying_answer' as const) : ('followup_question' as const),
      role: 'user' as const,
      message: question,
    };
  };

  const handleInput = useCallback(
    (
      message: ChatUserMessage | null,
      useMessagesFromState = true,
      updateLoadingState = true,
      customExploration?: Exploration,
    ) => {
      const correlationId = generateUUID();
      const exploration = customExploration ? customExploration : currentExploration;
      const conversationCells = getConversationCells(exploration.view.cells, cell.conversationId);

      setConversationDraft(cell.conversationId, null);
      const cellWithStateMessages = {
        ...cell,
        messages: [...messages, ...(message !== null ? [message] : [])],
      };

      const { messages: conversationMessages, selector } = convertConversationCellsToMessages([
        ...conversationCells,
        ...(useMessagesFromState ? [cellWithStateMessages] : []),
      ]);

      if (updateLoadingState) {
        setStatusMessage(sample(loadingMessages));
        setStatus('loading');
      }

      const explorationWithoutConversations = replaceCells(
        exploration,
        exploration.view.cells.filter(
          (c) =>
            !isConversationCell(c) ||
            (!isChatCell(c) &&
              c.conversationId === cell.conversationId &&
              isConversationStart(c, exploration.view.cells)),
        ),
      );

      abortSignalRef.current.abort();
      abortSignalRef.current = new AbortController();
      queryNextMessage({
        variables: {
          accountId: account.accountId,
          exploration: exportExploration(explorationWithoutConversations),
          correlationId,
          selectors: selector !== undefined ? [selector] : [],
          conversation: {
            conversationId: cell.conversationId,
            messages: conversationMessages.map(exportChatMessage),
          },
        },
        context: {
          // TODO: Read from import.meta.env.VITE_REQUEST_TIMEOUT_MS
          fetchOptions: {
            signal: AbortSignal.any([
              abortSignalRef.current.signal,
              AbortSignal.timeout(60 * 1000),
            ]),
          },
        },
      });
      const lastMessage = message ?? conversationMessages.findLast((msg) => msg.role === 'user');

      trackEvent('Exploration Chat Generation Started', {
        searchTerm: lastMessage?.message,
        conversationId: cell.conversationId,
        // Other blocks exist besides this chat block
        hasCurrentExploration: exploration.view.cells.length > 1,
        correlationId,
      });
    },
    [
      account.accountId,
      cell,
      currentExploration,
      messages,
      queryNextMessage,
      trackEvent,
      setConversationDraft,
    ],
  );

  const lastCellInConversation = useMemo(
    () => isConversationEnd(cell, currentExploration.view.cells),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentExploration.view.cells.length],
  );

  const editableInitialMessage = useMemo(() => {
    const [lastMessage] = messages.slice(-1);

    return (
      lastCellInConversation &&
      messages.length === 1 &&
      lastMessage.role === 'user' &&
      !queryState.loading &&
      !queryState.error
    );
  }, [lastCellInConversation, messages, queryState.loading, queryState.error]);

  useEffect(() => {
    setMessages(cell.messages);
  }, [cell.messages]);

  useEffect(() => {
    if (!lastCellInConversation) {
      if (cell.messages.length !== messages.length) {
        setMessages(cell.messages);
      }
      initialLoadCheck.current = true;
      return;
    }

    const [lastMessage] = messages.slice(-1);
    if (lastMessage?.role !== 'user' || queryState.loading || queryState.error) {
      initialLoadCheck.current = true;
      return;
    }

    if (lastMessage.type === 'initial_user_prompt' && !initialLoadCheck.current) {
      setStatus('loading');
      initialLoadCheck.current = true;
      return handleInput(lastMessage, false);
    }

    if (status === 'loading') {
      setStatus(undefined);
    }

    if (messages.length !== 1) {
      setMessages(messages.slice(0, -1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cell.messages,
    messages,
    queryState.loading,
    queryState.error,
    handleInput,
    lastCellInConversation,
  ]);

  const pendingFirstInput = useMemo(() => {
    const cells = getConversationCells(currentExploration.view.cells, cell.conversationId);
    return cells.every((cell) => !isChatCell(cell) || !cell.messages.length);
  }, [currentExploration.view.cells, cell.conversationId]);

  const handleGeneratedCells = (cells: Cell[]) => {
    const newCells = [
      ...cells.map((c) => ({ ...c, conversationId: cell.conversationId })),
      buildEmptyChatCell(cell.conversationId),
    ];

    const newExploration = replaceCells(
      currentExploration,
      currentExploration.view.cells.reduce<Cell[]>((result, c, idx) => {
        if (idx !== cellIndex) {
          result.push(c);
          return result;
        }

        result.push(...[{ ...cell, messages }, ...newCells]);
        return result;
      }, []),
    );

    setExploration(newExploration);
    scrollToCell(newCells[0].id);
    if (selectedCell !== null) {
      selectCell(newCells[0].id);
    }
  };

  const handleAccept = () => {
    const cellsToKeep = getConversationCells(
      currentExploration.view.cells,
      cell.conversationId,
    ).filter((c) => !isChatCell(c) && belongsToConversation(c, cell.conversationId));

    const newExploration = replaceCells(
      currentExploration,
      currentExploration.view.cells.reduce<Cell[]>((result, c) => {
        if (!isConversationCell(c) || !belongsToConversation(c, cell.conversationId)) {
          result.push(c);
          return result;
        }

        if (cellsToKeep.some((convCell) => convCell.id === c.id)) {
          result.push(unlinkConversationCell(c));
        }

        return result;
      }, []),
    );

    setExploration(newExploration);
  };

  const handleRetry = () => {
    setStatus('loading');
    handleInput(null, true, false);
  };

  const handleCancel = () => {
    if (isConversationStart(cell, currentExploration.view.cells)) {
      return handleDiscard();
    }

    const newExploration = replaceCells(
      currentExploration,
      currentExploration.view.cells.reduce<Cell[]>((result, c, idx) => {
        if (idx !== cellIndex) {
          result.push(c);
          return result;
        }

        result.push(buildEmptyChatCell(cell.conversationId));
        return result;
      }, []),
    );

    setExploration(newExploration);
  };

  const handleDiscard = () => {
    const lastInputCellIndex = findLastIndex(
      currentExploration.view.cells,
      (c) =>
        belongsToConversation(c, cell.conversationId) &&
        isChatCell(c) &&
        c.messages.some((m) => m.role === 'user'),
    );
    const conversationCells = getConversationCells(
      currentExploration.view.cells,
      cell.conversationId,
    );

    // User requested edits & discards immediately
    if (pendingFirstInput) {
      const cellToEdit = conversationCells.at(0);

      return setExploration(removeConversationEditFlow(currentExploration, cell.id, cellToEdit));
    }

    if (lastInputCellIndex === cellIndex) {
      const lastUserMessageIndex = getLastUserMessageIndex(messages);
      setConversationDraft(cell.conversationId, messages[lastUserMessageIndex].message);
    }

    const newExploration = replaceCells(
      currentExploration,
      currentExploration.view.cells
        .map((c, index) => {
          if (index === lastInputCellIndex && isChatCell(c)) {
            const cellMessages = cell.id === c.id ? messages : c.messages;
            const lastUserMessageIndex = getLastUserMessageIndex(cellMessages);
            return {
              ...c,
              messages: cellMessages.slice(
                0,
                lastUserMessageIndex === 0 ? 1 : lastUserMessageIndex,
              ),
            };
          }

          return c;
        })
        .filter((c, index) => {
          if (!belongsToConversation(c, cell.conversationId)) {
            return true;
          }

          return index <= lastInputCellIndex;
        }),
    );

    if (
      newExploration.view.cells.length === 0 ||
      (conversationCells.length <= 1 &&
        isChatCell(conversationCells[0]) &&
        conversationCells[0].messages.length === 0) ||
      editableInitialMessage
    ) {
      const firstCellIndex = currentExploration.view.cells.findIndex((c) =>
        belongsToConversation(c, cell.conversationId),
      );
      const firstCell = currentExploration.view.cells[firstCellIndex];
      const firstMessage = isChatCell(firstCell) ? firstCell.messages[0] : null;
      firstMessage && setConversationDraft('new', firstMessage.message);
      setNewCellIndex(firstCellIndex);
      setExploration(removeExplorationConversation(newExploration, cell.conversationId));
    } else {
      const lastInput = messages.findLast((msg) => msg.role === 'user');
      if (lastInput) {
        setConversationDraft(cell.conversationId, lastInput.message);
      }
      setExploration(newExploration);
    }
  };

  const handleMessageChange = (message: ChatUserMessage) => {
    const newExploration = replaceCells(
      currentExploration,
      updateConversationMessage(
        cell.conversationId,
        discardConversationAfterMessage(
          cell.conversationId,
          currentExploration.view.cells,
          message.id,
        ),
        message,
      ),
    );
    setExploration(newExploration);
    handleInput(message, false, true, newExploration);
  };

  return (
    <ChatInput
      messages={messages}
      status={status}
      statusMessage={statusMessage}
      pendingFirstInput={pendingFirstInput}
      editableInitialMessage={editableInitialMessage}
      draft={conversationDrafts[cell.conversationId] ?? undefined}
      onSubmit={(question) => {
        const message = createMessage(question);
        setMessages([...messages, message]);
        handleInput(message);
      }}
      onAccept={handleAccept}
      onDiscard={handleDiscard}
      onCancel={handleCancel}
      onRetry={handleRetry}
      onMessageChange={handleMessageChange}
    />
  );
};

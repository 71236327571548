import { generateUUID } from '@/lib/utils';
import {
  BasePipeline,
  DereferencedPipeline,
  DereferencedPipelineOperation,
  JoinPipelineOperation,
  Pipeline,
  PipelineOperation,
  PipelineWithParent,
} from '@/explore/types';

export const generatePipelineId = generateUUID;

export const isBasePipeline = (pipeline: Pipeline): pipeline is BasePipeline =>
  'baseModelId' in pipeline;

export const isPipelineWithParent = (pipeline: Pipeline): pipeline is PipelineWithParent =>
  'parentId' in pipeline;

export const isPipelineChildOf = (pipeline: Pipeline, potentialParent: Pipeline) =>
  isPipelineWithParent(pipeline) && pipeline.parentId === potentialParent.pipelineId;

export const isPipelineParentOf = (pipeline: Pipeline, potentialChild: Pipeline) =>
  isPipelineWithParent(potentialChild) && potentialChild.parentId === pipeline.pipelineId;

export const isCombinePipeline = (pipeline: Pipeline) =>
  pipeline.operations.some(({ operation }) => operation === 'joinPipeline');

export const createPipeline = (pipeline: Pipeline) =>
  isBasePipeline(pipeline) ? createBasePipeline(pipeline) : createPipelineWithParent(pipeline);

export const createBasePipeline = ({
  baseModelId,
  operations,
}: {
  baseModelId: string;
  operations: PipelineOperation[];
}): BasePipeline => ({
  pipelineId: generatePipelineId(),
  baseModelId,
  operations,
});

export const createPipelineWithParent = ({
  parentId,
  operations,
}: {
  parentId: string;
  operations: PipelineOperation[];
}): PipelineWithParent => ({
  pipelineId: generatePipelineId(),
  parentId,
  operations,
});

export const createDereferencedPipeline = ({
  baseModelId,
  operations,
}: {
  baseModelId: string;
  operations: DereferencedPipelineOperation[];
}): DereferencedPipeline => ({
  pipelineId: generatePipelineId(),
  baseModelId,
  operations,
});

const ensureOperationOrder = (operations: PipelineOperation[]): PipelineOperation[] => {
  // A hack to avoid "unflattenable pipelines" on a type-level
  const joinPipelineOperationIndex = operations.findIndex(
    (operation) => operation.operation === 'joinPipeline',
  );
  return operations.slice(joinPipelineOperationIndex === -1 ? 0 : joinPipelineOperationIndex);
};

// Makes sure that pipeline does not reference parentPipeline by replacing references to parentPipeline
// either from parentId or any of pipeline operations.
export const removeReferenceToParentPipeline = (
  pipeline: Pipeline,
  parentPipeline: Pipeline,
): Pipeline => {
  const operations = pipeline.operations.map((operation) => {
    if (operation.operation !== 'joinPipeline' || parentPipeline.operations.length > 0) {
      return operation;
    }

    return removeJoinPipelineOperationReferenceToPipeline(operation, parentPipeline);
  });

  if (isPipelineChildOf(pipeline, parentPipeline)) {
    return {
      pipelineId: pipeline.pipelineId,
      ...(isPipelineWithParent(parentPipeline)
        ? { parentId: parentPipeline.parentId }
        : { baseModelId: parentPipeline.baseModelId }),
      operations: [...parentPipeline.operations, ...operations],
    };
  }

  return { ...pipeline, operations: ensureOperationOrder(operations) };
};

const removeJoinPipelineOperationReferenceToPipeline = (
  operation: JoinPipelineOperation,
  pipeline: Pipeline,
): JoinPipelineOperation => {
  if (!isPipelineChildOf(operation.parameters.pipeline, pipeline)) {
    return operation;
  }

  return {
    ...operation,
    parameters: {
      ...operation.parameters,
      pipeline: removeReferenceToParentPipeline(operation.parameters.pipeline, pipeline),
    },
  };
};

import { findLast } from 'lodash';

import {
  Cell,
  ChatAssistantMessage,
  ChatCell,
  ChatExploration,
  ChatMessage,
  ChatUserMessage,
  ConversationCell,
} from '@/explore/types';

import { isChatCell, updateChatCellMessage } from './chat-cell';

export const isConversationCell = (cell: Cell): cell is ConversationCell =>
  'conversationId' in cell;

export const belongsToConversation = (cell: Cell, conversationId: string) =>
  isConversationCell(cell) && cell.conversationId === conversationId;

export function getConversationCells(cells: Cell[], conversationId: string): ConversationCell[] {
  // Double filtering is required here to satisfy types
  return cells.filter(isConversationCell).filter((cell) => cell.conversationId === conversationId);
}

export function convertConversationCellsToMessages(cells: ConversationCell[]) {
  const result: (ChatMessage | ChatExploration)[] = [];
  let nonChatCells: ConversationCell[] = [];
  let referencedCellId: string | undefined = undefined;

  cells.forEach((cell, index) => {
    if (!isChatCell(cell)) {
      const isFirstCell = index === 0;
      const nextCell = cells.at(index + 1);
      // Skip the cell that user asked to edit
      if (isFirstCell && nextCell !== undefined && isChatCell(nextCell)) {
        referencedCellId = cell.id;
        return;
      }

      return nonChatCells.push(cell);
    }

    // If there are non-chat cells collected, wrap them in an Exploration object
    if (nonChatCells.length > 0) {
      result.push({
        id: cell.id,
        role: 'assistant',
        type: 'exploration',
        exploration: {
          explorationId: cell.conversationId,
          name: 'Exploration',
          labels: {},
          parameters: [],
          view: { cells: nonChatCells },
        },
      } as ChatExploration);
      nonChatCells = [];
    }

    cell.messages.forEach((message) => {
      if (message.role === 'assistant') {
        const chatMessage: ChatAssistantMessage = {
          id: message.id,
          role: 'assistant',
          type: 'clarifying_question',
          message: message.message ?? '',
        };
        result.push(chatMessage);
      }

      if (message.role === 'user') {
        // User requested an edit from an existing cell
        if (result.length === 0 && message.type === 'followup_question') {
          const chatMessage: ChatUserMessage = {
            id: message.id,
            role: 'user',
            type: 'initial_user_prompt',
            message: message.message,
          };
          return result.push(chatMessage);
        }

        const chatMessage: ChatUserMessage = {
          id: message.id,
          role: 'user',
          type: message.type as 'initial_user_prompt' | 'clarifying_answer' | 'followup_question',
          message: message.message ?? '',
        };
        result.push(chatMessage);
      }
    });
  });

  // If there are remaining non-chat cells, wrap them in an Exploration object
  if (nonChatCells.length > 0) {
    result.push({
      id: nonChatCells[0].id,
      role: 'assistant',
      type: 'exploration',
      exploration: {
        explorationId: nonChatCells[0].conversationId,
        name: 'Exploration',
        labels: {},
        parameters: [],
        view: { cells: nonChatCells },
      },
    });
  }

  const selector =
    referencedCellId !== undefined ? `view.cells[?(@.id == "${referencedCellId}")]` : undefined;

  return { messages: result, selector };
}

export function unlinkConversationCell(cell: ConversationCell): Cell {
  if (isChatCell(cell)) {
    return cell;
  }

  const { conversationId: _, ...rest } = cell;
  return rest;
}

export const updateConversationMessage = (
  conversationId: string,
  cells: Cell[],
  message: ChatUserMessage,
) => {
  return cells.map((cell) => {
    if (
      isChatCell(cell) &&
      cell.conversationId === conversationId &&
      cell.messages.some(({ id }) => id === message.id)
    ) {
      return updateChatCellMessage(cell, message);
    }

    return cell;
  });
};

export const discardConversationAfterMessage = (
  conversationId: string,
  cells: Cell[],
  messageId: string,
): Cell[] => {
  const messageCellIdx = cells.findIndex(
    (cell) => isChatCell(cell) && cell.messages.some((m) => m.id === messageId),
  );

  if (messageCellIdx === -1) {
    return cells;
  }

  return cells
    .map((cell, cellIdx) => {
      if (cellIdx === messageCellIdx && isChatCell(cell)) {
        const messageIdx = cell.messages.findIndex(({ id }) => id === messageId);

        if (messageIdx === -1) {
          return cell;
        }

        return {
          ...cell,
          messages: cell.messages.slice(0, messageIdx + 1),
        };
      }

      return cell;
    })
    .filter((cell, index) => {
      if (!belongsToConversation(cell, conversationId)) {
        return true;
      }

      return index <= messageCellIdx;
    });
};

export const isConversationStart = (cell: ConversationCell, cells: Cell[]) => {
  const conversationStart = cells.find((c) => belongsToConversation(c, cell.conversationId));
  return conversationStart?.id === cell.id;
};

export const isConversationEnd = (cell: ConversationCell, cells: Cell[]) => {
  const lastCell = findLast(cells, (c) => belongsToConversation(c, cell.conversationId));
  return lastCell?.id === cell.id;
};

export const normalizeConversationBlocks = (cells: Cell[]) => {
  let chatCell: ChatCell | null = null;
  cells = cells.reduce<Cell[]>((acc, c) => {
    if (isChatCell(c)) {
      chatCell = c;
      return acc;
    } else if (chatCell !== null) {
      acc.push(chatCell);
      chatCell = null;
    }
    return [...acc, c];
  }, []);
  return [...cells, ...(chatCell !== null ? [chatCell] : [])];
};

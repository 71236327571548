import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { isEmpty } from 'lodash';

import { ArrayKeyedMap } from '@/lib/array-keyed-map';

import { Json } from '@/lib/types';

import { Exploration, ExplorationParameters, Model } from '../types';
import {
  getLinkableExplorations,
  isExplorationReferencedByProperty,
  ExplorationLike,
} from './linking';
import { getModelExploration } from './model-exploration';
import { migrateVisualisations } from './exploration';

const ExplorationHashKey = 'exploration';
const ParametersHashKey = 'params';

const sanitizeHash = (hash: string) => hash.replace(/^#/, '');

const getHashParameters = (hash: string) => new URLSearchParams(sanitizeHash(hash));

export const getHashParameter = (hash: string, key: string) => getHashParameters(hash).get(key);

const setHashParameter = (key: string, value: string | null, hash = '') => {
  const params = getHashParameters(hash);
  if (value !== null) {
    params.set(key, value);
  } else {
    params.delete(key);
  }

  return params;
};

export const decodeExplorationHash = (hash: string): Exploration | null => {
  const encodedExploration = getHashParameter(hash, ExplorationHashKey);

  if (encodedExploration === null || encodedExploration.length === 0) {
    return null;
  }

  const exploration = decodeString<Exploration>(encodedExploration);

  return exploration !== null ? migrateVisualisations(exploration) : null;
};

export const decodeExplorationParamsHash = (hash: string): Record<string, Json> | null => {
  const encodedParams = getHashParameter(hash, ParametersHashKey);

  if (encodedParams === null || encodedParams.length === 0) {
    return null;
  }

  return decodeString<Record<string, Json>>(encodedParams);
};

export const buildExplorationUrl = (
  exploration: { explorationId: string },
  explorationParameters?: ExplorationParameters | null,
  extraParameters?: { [key: string]: string },
) => {
  const hashParams = isEmpty(explorationParameters)
    ? new URLSearchParams()
    : setHashParameter(
        ParametersHashKey,
        compressToEncodedURIComponent(JSON.stringify(explorationParameters)),
      );

  if (!isEmpty(extraParameters)) {
    Object.entries(extraParameters).forEach(([key, value]) => {
      hashParams.set(key, value);
    });
  }

  const hash = hashParams.toString();

  return `/explore/${exploration.explorationId}${hash.length > 0 ? '#' : ''}${hash}`;
};

export const buildExplorationHashUrl = (exploration: Exploration) => {
  const explorationHash = setHashParameter(
    ExplorationHashKey,
    compressToEncodedURIComponent(JSON.stringify(exploration)),
  ).toString();

  return `/explore#${explorationHash}`;
};

const decodeString = <T>(encoded: string) => {
  const decoded = decompressFromEncodedURIComponent(encoded);
  return decoded === null ? null : (JSON.parse(decoded) as T);
};

export const addExplorationUrlBuilders = <
  T extends { key: string; relation?: { key: string; modelId: string } },
  E extends ExplorationLike & { explorationId: string },
>(
  properties: T[],
  explorations: E[],
): (T & { buildLink?: (record: Record<string, unknown>) => string })[] => {
  const linkableExplorations = getLinkableExplorations(properties, explorations);

  const paramToKeyMap = new ArrayKeyedMap(
    properties.map(({ relation, key }) => [[relation?.modelId, relation?.key], key]),
  );

  return properties.map((property) => {
    const exploration = linkableExplorations.find((exploration) =>
      isExplorationReferencedByProperty(exploration, property),
    );

    if (exploration === undefined) {
      return property;
    }

    const buildLink = (record: Record<string, unknown>) => {
      const parameters = exploration.parameters.reduce((acc, { key, modelId }) => {
        if (property.relation?.modelId === modelId && property.relation.key === key) {
          return { ...acc, [key]: record[property.key] };
        }

        const propertyKey = paramToKeyMap.get([modelId, key]);
        if (propertyKey === undefined) {
          return acc;
        }

        return { ...acc, [key]: record[propertyKey] };
      }, {});

      return buildExplorationUrl(exploration, parameters);
    };

    return { ...property, buildLink };
  });
};

export const getExplorationFromUrl = (
  hash: string,
  explorations: Exploration[],
  models: Model[],
): Exploration | null => {
  const { exploration, explorationId, modelId } = getExplorationHashParams(hash) ?? {
    exploration: null,
    explorationId: null,
    modelId: null,
  };

  if (exploration !== null) {
    return decodeExplorationHash(hash);
  }

  if (explorationId !== null) {
    return explorations.find((exploration) => exploration.explorationId === explorationId) ?? null;
  }

  if (modelId !== null) {
    const model = models.find((model) => model.modelId === modelId);
    return model !== undefined ? getModelExploration(model) : null;
  }

  return null;
};

/**
 * @param hash window.location.hash value
 * @returns null if hash parameter does not contain anything that can be used to extract exploration from the url
 */
export const getExplorationHashParams = (hash: string) => {
  const urlParams = getHashParameters(hash);

  const result = {
    exploration: urlParams.get('exploration'),
    explorationId: urlParams.get('explorationId'),
    modelId: urlParams.get('modelId'),
  };

  if (Object.values(result).every((value) => value === null)) {
    return null;
  }

  return result;
};

export const getHashSearchTerm = (hash: string) => getHashParameter(hash, 'ask');

export const setHashSearchTerm = (hash: string, searchTerm: string) =>
  setHashParameter('ask', searchTerm !== '' ? searchTerm : null, hash);

export function isValidLink(input: unknown) {
  try {
    const { protocol } = new URL(String(input));
    return protocol === 'http:' || protocol === 'https:';
  } catch {
    return false;
  }
}

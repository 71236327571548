import { useState } from 'react';

import { ChatUserMessage } from '@/explore/types';
import { IconButton } from '@/components/button';

import { UserMessageInput } from './user-message-input';

import styles from './initial-message.module.scss';
import commonStyles from './chat-cell.module.scss';

interface InitialMessageProps {
  message: ChatUserMessage;
  initialValue?: string;
  editable?: boolean;
  onChange: (message: ChatUserMessage) => void;
  onDiscard: () => void;
}

export const InitialMessage = ({
  message,
  initialValue,
  editable = false,
  onChange,
  onDiscard,
}: InitialMessageProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (value: string) => {
    onChange({ ...message, message: value });
    setIsEditing(false);
  };

  const label = message.type === 'initial_user_prompt' ? 'Question' : 'Follow-up';

  return (
    <div className={(commonStyles.questionRow, styles.initialMessage)}>
      <div className={styles.label}>{label}:</div>
      <div className={styles.message}>
        {isEditing || editable ? (
          <UserMessageInput
            initialValue={initialValue ?? message.message}
            placeholder="Edit answer"
            actions={(value) => [
              {
                label: 'Send',
                disabled: value === message.message,
                onClick: () => handleSubmit(value),
              },
              !isEditing && editable
                ? {
                    label: 'Discard (Esc)',
                    onClick: onDiscard,
                    variant: 'secondary',
                    type: 'cancel',
                  }
                : {
                    label: 'Cancel (Esc)',
                    onClick: () => setIsEditing(false),
                    variant: 'secondary',
                    type: 'cancel',
                  },
            ]}
            autoFocus
          />
        ) : (
          <div className={commonStyles.response}>
            <button
              type="button"
              className={commonStyles.response}
              onClick={() => setIsEditing(true)}>
              {message.message}
            </button>
            <IconButton
              icon="Edit2"
              iconSize="small"
              className={commonStyles.responseEditBtn}
              onClick={() => setIsEditing(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

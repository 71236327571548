import { useMemo } from 'react';

import { useAccountContext } from '@/lib/accounts/context';
import { isRecordsLikeCell } from '@/core/cell';
import { getNodes, useExplorationDataQuery } from '@/graphql';
import { EnumVariableDefinition, RecordsLikeCell } from '@/explore/types';
import { filterVariablesForPipeline } from '@/explore/utils';
import { dereferencePipeline } from '@/explore/pipeline/utils';
import { Select, withEmptyOptionForMissingValue } from '@/components/form/select';

import { getCellByPipelineId } from '../utils';
import { useExplorationContext } from '../exploration-context';

interface EnumInputProps {
  variable: EnumVariableDefinition;
  value: string;
  onChange: (value: string) => void;
}

export const EnumInput = (props: EnumInputProps) => {
  const { exploration } = useExplorationContext();

  // This should be props.variable.source === 'static' once we parse exploration state with zod schema.
  if (props.variable.source !== 'query') {
    return (
      <Select
        value={props.value}
        onChange={props.onChange}
        options={withEmptyOptionForMissingValue(
          props.variable.options.map(({ value }) => ({ label: value, value })),
          props.value,
        )}
      />
    );
  }

  const cell = getCellByPipelineId(props.variable.queryOptions.pipelineId, exploration);

  if (cell === undefined || !isRecordsLikeCell(cell)) {
    return <Select value={props.value} onChange={props.onChange} options={[]} />;
  }

  return <DynamicInputForCell cell={cell} {...props} />;
};

const DynamicInputForCell = (props: EnumInputProps & { cell: RecordsLikeCell }) => {
  const { exploration, getVariables } = useExplorationContext();
  const { account } = useAccountContext();

  const queryVariables = useMemo(() => {
    const pipeline = dereferencePipeline(props.cell.pipeline, exploration);
    const variables = filterVariablesForPipeline(pipeline, getVariables());

    return {
      accountId: account.accountId,
      baseModelId: pipeline.baseModelId,
      pipeline: [
        ...pipeline.operations,
        {
          operation: 'groupAggregate',
          parameters: {
            groups: [{ key: props.variable.queryOptions.field }],
            aggregations: [
              { type: 'count', property: { key: '_sup_variable_distinct', name: 'Count' } },
            ],
          },
        },
      ],
      variables,
    };
  }, [account.accountId, props.cell, exploration, getVariables, props.variable.queryOptions.field]);

  const { data } = useExplorationDataQuery({
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  const options = getNodes(data?.account?.query)
    ?.map((item) => item[props.variable.queryOptions.field])
    .map((item) => ({ value: item, label: String(item ?? '') }))
    .sort((a, b) => a.label.localeCompare(String(b.label)));

  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      options={withEmptyOptionForMissingValue(options, props.value)}
    />
  );
};

import { RefObject, useEffect } from 'react';

export const useEventListener = (
  event: string,
  eventHandler?: () => void,
  targetRef?: RefObject<HTMLElement | null>,
) => {
  useEffect(() => {
    const target = targetRef?.current ?? document;
    if (target === null || eventHandler === undefined) {
      return;
    }
    target.addEventListener(event, eventHandler);
    return () => {
      target.removeEventListener(event, eventHandler);
    };
  }, [event, eventHandler, targetRef]);
};

import { RefObject, useEffect } from 'react';

export type ModifierKey = 'ctrl' | 'shift' | 'alt' | 'meta';
export type TargetKeys = string | [string, ...(ModifierKey | ModifierKey[])[]];
type EventCallback = (event: KeyboardEvent) => void;

export const eventIncludesKey = (event: KeyboardEvent, keys: TargetKeys) => {
  const [key, ...modifierGroups] = Array.isArray(keys) ? keys : [keys];
  if (event.key.toLocaleLowerCase() === key.toLocaleLowerCase()) {
    if (modifierGroups.length === 0) {
      return true;
    }

    return modifierGroups.some((modifierOrGroup) =>
      Array.isArray(modifierOrGroup)
        ? modifierOrGroup.every((modifier) => event[`${modifier}Key`])
        : event[`${modifierOrGroup}Key`],
    );
  }

  return false;
};

const isInputNode = (node: EventTarget | null) => {
  return (
    node instanceof HTMLInputElement ||
    node instanceof HTMLTextAreaElement ||
    node instanceof HTMLSelectElement ||
    node instanceof HTMLButtonElement ||
    (node instanceof HTMLElement && node.isContentEditable)
  );
};

/**
 * A convenience hook for listening to keyboard events and firing a callback.
 * Example: `useKeyPress('Escape', () => inputRef.current?.blur(), inputRef);`
 *
 * @param targetKeys The keys to listen for (from event.key); if you wish to include a combination with modifier key(s),
 * pass an array with the key and the modifier keys (e.g. `['s', 'ctrl']` or `['Enter', 'meta', 'shift']`).
 * If multiple modifiers are passed in, only 1 of them has to be pressed for the callback to fire.
 * Each modifier passed can also be an array of multiple modifiers, in which case all of them have to be pressed
 * (e.g. `['Y', ['ctrl', 'alt']]` would require both ctrl and alt to be pressed along with Y).
 * @param callback  The callback to fire when the key is pressed
 * @param options.capture  The capture boolean determines if the event should be called before all other similar events.
 * @param options.includeInputs If true, the callback will fire even if an input that is not the targetRef is focused.
 * @param options.targetRef (optional) A ref to the element to listen on. If not provided, the document body will be used.
 */
export const useKeyPress = (
  targetKeys: TargetKeys,
  callback: EventCallback,
  options: {
    capture?: boolean;
    includeInputs?: boolean;
    targetRef?: RefObject<HTMLElement | null>;
  } = {},
) => {
  useEffect(() => {
    const { targetRef, capture, includeInputs = false } = options;

    const handler = (event: KeyboardEvent) => {
      (includeInputs || event.target === targetRef?.current || !isInputNode(event.target)) &&
        eventIncludesKey(event, targetKeys) &&
        callback(event);
    };

    const target = targetRef?.current ?? document.body;
    target.addEventListener('keydown', handler, capture);

    return () => {
      target.removeEventListener('keydown', handler, capture);
    };
  }, [callback, targetKeys, options]);
};

import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { useBuildAccountUrl } from '@/lib/accounts/context';
import { useEventListener } from '@/lib/hooks/use-event-listener';
import { NavigableListItemOutput } from '@/lib/hooks/use-navigable-list';

import { buildExplorationFromCells } from '@/core/exploration';

import { Exploration } from '../types';
import { Icon } from '../../components/icon';
import { PipelinePreview } from '../exploration/pipeline-preview';
import { buildExplorationHashUrl } from '../utils';
import { CellListItemData } from '.';
import { extractCellFromExploration } from '../exploration/utils';

import { useMetadataContext } from '../metadata-context';

import * as styles from './exploration-search.module.scss';

interface CellListProps {
  exploration: Exploration;
  listItems: NavigableListItemOutput<CellListItemData>[];
}

export const CellList = (props: CellListProps) => {
  return (
    <ol>
      {props.listItems.map((item, i) => {
        return <CellItem key={i} listItem={item} exploration={props.exploration} />;
      })}
    </ol>
  );
};

interface CellItemProps {
  exploration: Exploration;
  listItem: NavigableListItemOutput<CellListItemData>;
}

const CellItem = (props: CellItemProps) => {
  const { listItem } = props;
  const { getIsFocused, setIsFocused } = listItem;

  const buildAccountUrl = useBuildAccountUrl();
  const { models } = useMetadataContext();
  const ref = useRef<HTMLAnchorElement>(null);

  const isFocused = getIsFocused();
  useEventListener('focus', () => setIsFocused(true), ref);
  useEffect(() => {
    if (isFocused) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isFocused, ref]);

  const handleMouseOver = debounce(
    () => {
      setIsFocused(true);
    },
    100,
    { leading: true, trailing: false },
  );

  const { cell } = listItem.getData<CellListItemData>();
  const { cell: extractedCell, dependencies } = extractCellFromExploration(
    cell.id,
    props.exploration,
  );
  const outputExploration = buildExplorationFromCells([extractedCell, ...dependencies]);
  const url = buildAccountUrl(buildExplorationHashUrl(outputExploration));

  return (
    <Link
      to={url}
      className={classNames(styles.listItem, { [styles.focus]: isFocused })}
      onMouseOver={handleMouseOver}
      onMouseMove={handleMouseOver}
      onClick={listItem.onClick}
      ref={ref}>
      <Icon name="Block" size={32} className={styles.icon} />
      <div className={styles.name}>{cell.title}</div>
      <div className={styles.pipeline}>
        <PipelinePreview
          models={models}
          title={cell.title ?? 'Untitled'}
          exploration={props.exploration}
          pipeline={cell.pipeline}
          interactive={false}
        />
      </div>
    </Link>
  );
};
